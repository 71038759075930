import { CloseIcon } from 'yet-another-react-lightbox';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import { useTranslate } from 'src/locales';

import { ConfirmDialogProps } from './types';

// ----------------------------------------------------------------------

export default function ConfirmDialog({
  title,
  content,
  action,
  open,
  cancel,
  loading,
  onClose,
  ...other
}: ConfirmDialogProps) {
  const { t } = useTranslate();
  return (
    <Dialog fullWidth maxWidth="sm" open={open} {...other}>
      <CloseIcon
        color="black"
        onClick={onClose}
        cursor="pointer"
        style={{ alignSelf: 'end', margin: '2rem 0 0 2rem' }}
      />
      <DialogTitle textAlign="center" sx={{ pt: 0, pb: 2 }}>
        {title}
      </DialogTitle>

      {content && (
        <DialogContent sx={{ typography: 'body2', textAlign: 'center' }}> {content} </DialogContent>
      )}

      <DialogActions>
        <Button variant="outlined" fullWidth color="inherit" onClick={onClose} disabled={loading}>
          {cancel || t('form.delete.cancel_button')}
        </Button>
        {action}
      </DialogActions>
    </Dialog>
  );
}
