export const en = {
  maintenance_request_campaign: {
    title: 'Maintenance Request Campaign',
  },
  caption: {
    wallets: 'Wallets',
    title: 'Portfolios',
    create: 'Create New Portfolio',
    detail_portfolio: 'Portfolio Details',
    edit: 'Edit Portfolio',
    status: {
      active: 'Active',
      inactive: 'Inactive',
    },
    add_new_portfolio: 'Add New Donor Portfolio',
    edit_portfolio: 'Save Portfolio',
    confirmation_delete_portfolio: 'Are You Sure You Want To Delete This Portfolio?',
    donor_information: 'Donor Information',
    portfolio_information: 'Portfolio Information',
    select_donors: 'Select One Of Donors',
    select: 'Select',
    contact_information: 'Contact Information',
    realtime_balance: 'Real-Time Balance',
    campaign: {
      all_campaigns: 'All Campaigns',
      currently_campaigns: 'Currently Campaigns',
      finished_campaigns: 'Finished Campaigns',
    },
    search: 'Search ...',
    sort_by: 'Sort By',
    latest: 'Latest',
    oldest: 'Oldest',
    popular: 'Popular',
    filter: 'Filter',
    balance_history: 'Balance History',
    copied_notification: 'Your detail portfolio wallet has been copied!',
    error_not_select_donor: 'You must select a donor first!',
    deposit_amount: 'Deposit Amount',
    transaction_history: 'Transaction History',
    total_money_donated: 'Total Money Donated',
    total_remaining_amount: 'Total Remaining Amount',
    notify_donor: 'Send a notification to the donor that a new endowment amount has been added',
    make_donation: 'Make Donation from this Portfolio',
    donation_details: 'Donation Details',
    add_another_donation: 'Add another donation',
    wallet_and_amount: 'wallet and amount',
    discuss_with_donor: `Discuss with donor`,
  },
  button: {
    share_portfolio: 'Share Portfolio',
    delete_portfolio: 'Delete Portfolio',
    create_portfolio: 'Create New Portfolio',
    create_donor_portfolio: 'Create Donor Portfolio',
    continue: 'Continue',
    edit_portfolio: 'Edit Portfolio',
    add_endowment_amount: 'Add Endowment Amount',
    periodic_report: 'Periodic Report',
    view_transaction_history: 'View Transaction History',
    share: 'Share',
    delete: 'Delete',
    apply: 'apply',
    reset_filter: 'reset filter',
    add_amount: 'Add Amount',
    make_donations: 'Make Donations',
    add_donation: 'Add Donation',
  },
  form: {
    note: {
      label: 'Note',
    },
    portfolio_name: {
      label: 'Portfolio Name',
      required: 'Porfolio Name is required!',
    },
    creation_date: {
      label: 'Creation Date',
      required: 'Created date is required!',
    },
    deposit: {
      label: 'Deposit',
      required: 'Deposit is required!',
      min_0: 'Must be greater than 0',
    },
    balance: {
      label: 'Balance',
      required: 'Balance is required!',
      min_1: 'Must be greater than 1',
    },
    status: {
      label: 'Status',
      required: 'Status is required!',
    },
    type: {
      label: 'Type',
      required: 'Portofolio type is required!',
    },
    region: {
      label: 'Region',
      required: 'Region is required!',
    },
    full_name: {
      label: 'Full Name',
      required: 'Full Name is required!',
    },
    phone_number: {
      label: 'Phone Number',
      required: 'Phone Number is required!',
      max: 'Phone Number must be 10 digits!',
      length_equals: 'Phone number length must be 9 digits',
      valid: 'Phone Number must be valid!',
    },
    email: {
      label: 'Email',
      required: 'Email is required!',
    },
    selected_reference: {
      label: 'Project / Campaign to be Supported',
      required: 'This field is not to be empty',
    },
    deposit_amount: {
      label: 'Amount',
      required: 'Amount is required!',
      max: 'Amount must be lower than balance!',
    },
    category: {
      label: 'Category',
    },
    transaction_date: {
      label: 'Transaction Date',
    },
    remaining_amount: {
      label: 'Remaining Amount',
    },
    donation_type: {
      label: 'Select Donation Type',
      required: 'Donation Type is required!',
    },
    donation_item: {
      label: 'Select an item',
      required: 'An item is required!',
    },
    donation_amount: {
      label: 'Donation Amount',
      placeholder: 'Enter Donation Amount',
      required: 'Donation Amount is required!',
    },
    wallet_name: {
      label: 'choose portfolio',
      placeholder: 'choose portfolio',
    },
    withdrawal_amount: {
      label: 'withdrawal amount',
      placeholder: 'withdrawal amount',
    },
    add: {
      label: 'add another portfolios',
      value: 'add another portfolios',
    },
  },
  filter: {
    filter: 'filter',
    started_balance: 'started balance',
    ends: 'ends balance',
    status: 'status',
    type: 'type',
    campaign: 'campaign',
    project: 'project',
    marketplace: 'marketplace',
  },
};
