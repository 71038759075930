import { ar as menu } from 'src/layouts/translation/ar';
import { ar as response } from 'src/locales/response/ar';
import { ar as exception } from 'src/locales/exception/ar';

import { ar as section } from 'src/sections/translation/ar';
import { ar as auth } from 'src/sections/auth/translate/ar';

const ar = {
  roles: {
    CLUSTER_ADMIN: 'مسؤول',
    OPERATOR: 'مكتب الإدارة',
    EVALUATOR: 'المقيِّم',
    VENDOR: 'مقاول',
    DONOR: 'جهات مانحة',
  },
  place_types: {
    HOSPITAL: 'مستشفى',
    RESTAURANT: 'مطعم',
    SCHOOL: 'مدرسة',
    GARDEN: 'حديقة',
    STADIUM: 'ملعب',
    distances: {
      50: '٥٠م',
      100: '١٠٠م',
      150: '١٥٠م',
      200: '٢٠٠م',
    },
  },
  caption: {
    empty_data: 'لاتوجد بيانات',
    empty_notif: 'لم يتم العثور على إشعار',
    rows_per_page: 'صفوف لكل صفحة',
    dense: 'جدول كبير',
    type: {
      CAMPAIGN: 'حملة',
      PROJECT: 'مسجد',
      ITEM: 'عنصر',
      MARKETPLACE: 'المتجر',
      MAINTENANCE_REQUEST: 'طلبات الصيانة',
      ALL: 'الجميع',
    },
    status: {
      approved: 'معتمد',
      finished: 'منتهي',
      pending: 'قيد الانتظار',
      rejected: 'مرفوض',
      published: 'نشرت',
      deleted: 'تم الحذف',
      verified: 'تم التحقق منه',
      unverified: 'لم يتم التحقق منها',
      default: 'افتراضي',
      APPROVED: 'معتمد',
      PENDING: 'قيد الانتظار',
      SUCCESS: 'نجاح',
      CANCELLED: 'ألغيت',
      FAILED: 'فشل',
      DECLINED: 'مرفوض',
      EXPIRED: 'منتهي الصلاحية',
      REJECTED: 'مرفوض',
      DONE: 'منتهي',
      OPEN: 'نشط',
      FORWARD: 'إلى الأمام',
      FORWARDED: 'تم إعادة توجيهها',
      CLOSED: 'مغلق',
      DELETED: 'تم الحذف',
      VERIFIED: 'تم التحقق منه',
      UNVERIFIED: 'لم يتم التحقق منها',
      ASSIGNED: 'مُكَلَّف',
      REASSIGNED: 'تم إعادة تعيينه',
      RESOLVED: 'حل',
      not_applied: 'غير مطبق',
    },
    empty_campaign: 'الحملة فارغة',
    empty_description_campaign:
      'تبدو مثل الفلترة أو بيانات الحملة فارغة في قاعدة البيانات الخاصة بنا',
    go_to_home: 'اذهب إلى الصفحة الرئيسية',
    welcome_to_ommar: 'مرحباً بكم في مساجدنا',
    success_resend: 'تم التحقق من البريد الإلكتروني بنجاح',
    verify_email: 'تحقق من البريد الإلكتروني',
    resend_verification: 'إعادة التحقق من البريد الإلكتروني',
    token_expired: 'انتهت صلاحية الرمز',
    verify_your_account: 'تحقق من حسابك',
    please_verify_your_email: 'يرجى التحقق من البريد الإلكتروني',
    success_registration: 'كان تسجيلك ناجحاً',
    coming_soon: 'قريباً',
  },
  form: {
    delete: {
      title: 'حذف',
      are_you_sure: 'هل أنت متأكد أنك تريد حذف المدونة؟',
      delete_button: 'حذف',
      cancel_button: 'إلغاء',
    },
    global: {
      are_you_sure: 'هل أنت متأكد',
      yes: 'نعم',
      no: 'لا',
    },
    search: {
      title: 'البحث',
    },
    filter: {
      button: {
        clear: 'إزالة التصفية',
        apply: 'تطبيق',
      },
      date: {
        select_start_date: 'حدد تاريخ البداية',
        select_end_date: 'حدد تاريخ النهاية',
        button: {
          cancel: 'إلغاء',
          select: 'تحديد',
        },
      },
    },
  },
  notification: {
    success: {
      created_project: 'تم إنشاء المسجد',
      created_campaign: 'تم إنشاء الحملة',
      deleted_campaign: 'تم حذف الحملة',
      approveed_project: 'تمت الموافقة على المسجد',
      rejected_project: 'تم رفض المسجد',
      project_edit_request: 'تم تقديم المسجد',
      approveed_campaign: 'تمت الموافقة على الحملة',
      rejected_campaign: 'تم رفض الحملة',
      campaign_edit_request: 'تم تقديم الحملة',
      created_item: 'تم إنشاء العنصر',
      updated_item: 'تم تحديث العنصر',
      rejected_item: 'تم رفض العنصر',
      created_vendor: 'تم إنشاء المقاول',
      edited_vendor: 'تم تحرير المقاول',
      approve_vendor: 'تمت الموافقة على المقاول',
      reject_vendor: 'تم رفض المقاول',
      apply_campaign: 'تم تقديم طلبك',
      created_operator: 'تم إنشاء مكتب الإدارة',
      edited_operator: 'تم تعديل مكتب الإدارة',
      deleted_operator: 'تم حذف مكتب الإدارة',
      created_evaluator: 'تمت إضافة المقيم',
      edited_evaluator: 'تم تحديث المقيم',
      deleted_evaluator: 'تم حذف المقيم',
      created_donor: 'تمت إضافة المانح',
      edited_donor: 'تم تحديث المانح',
      deleted_donor: 'تم حذف المانح',
      add_blog: 'تمت إضافة مدونة',
      edit_blog: 'تم تعديل المدونة',
      deleted_blog: 'تم حذف المدونة',
      comment_deleted: 'تم حذف التعليق',
      approved_vendor_campaign: 'تمت الموافقة على المقاول',
      rejected_vendor_campaign: 'تم رفض المقاول',
      add_progress_milestone: 'تم تقديم طلبك',
      accept_progress_milestone: 'تم قبول طلبك',
      reject_progress_milestone: 'تم رفض طلبك',
      add_invoice_progress: 'تم تقديم فاتورتك',
      pay_to_vendor_invoice: 'تم تقديم دفعتك لتلك الفاتورة',
      edit_account_info: 'تم تحديث الحساب الخاص بك',
      create_new_ticket: 'تم إنشاء تذكرة جديدة',
      approved_maintenance_request: 'تمت الموافقة على الطلب',
      rejected_maintenance_request: 'تم رفض الطلب',
      deleted_vendor: 'تم حذف المقاول',
      applied_to_maintenance: 'تم تقديم طلبك إلى المسؤول',
      accept_evaluator_request: 'تم قبول طلب المقيِّم',
      reject_evaluator_request: 'تم رفض طلب المقيِّم',
      accept_evaluation_task: 'تم قبول مهمة التقييم',
      reject_evaluation_task: 'تم رفض مهمة التقييم',
      submit_evaluation_task: 'تم تقديم مهمتك',
      created_mosque_maintenance: 'تم إنشاء حملة طلب الصيانة ',
      campaign_task_created: 'تم إنشاء مهمة الحملة',
      campaign_task_edited: 'تم تحديث مهمة الحملة',
      changes_to_major_donor: 'أصبح المانح بنجاح متبرعًا رئيسيًا',
      accept_major_donor_request: 'تم إنشاء محفظة جديدة',
      reject_major_donor_request: 'تم رفض طلب المانحين الرئيسيين',
      new_wallet_portfolios: 'تم إنشاء محفظة بنجاح',
      edit_wallet_portfolios: 'تم تعديل المحفظة بنجاح',
      new_deposit_amount: 'تم إضافة المبلغ بنجاح إلى محفظتك',
      make_donations_from_wallet: 'تم تقديم تبرعاتك إلى هذه المحفظة/المحفظة',
    },
    error: {
      has_ticket_not_finish: 'لديك تذكرة غير مكتملة بهذا النوع',
      balance_not_enough: 'رصيد المحفظة لا يكفي لتقديم هذا التبرع',
      cant_donate_more_than_needed: 'أحد التبرعات تعدى المبلغ المطلوب للتبرع',
      cant_deposit_to_mosque_and_campaign_in_the_same_mosque:
        'لا يمكن التبرع لمسجد و حملة تابعة لنفس المسجد في نفس العملية',
      same_campaign_error: 'لا يمكن تقديم أكثر من تبرع لنفس الغرض بنفس العملية',
      same_item_error: 'لا يمكن تقديم أكثر من تبرع لنفس العنصر بنفس العملية',
      same_maintenance_error: 'لا يمكن تقديم أكثر من تبرع لنفس طلب الصيانة بنفس العملية',
      same_project_error: 'لا يمكن تقديم أكثر من تبرع لنفس المسجد بنفس العملية',
    },
  },

  auth,
  menu,
  section,
  response,
  exception,
};

export default ar;
