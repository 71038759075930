import { Helmet } from 'react-helmet-async';

import { useParams } from 'src/routes/hooks';
import { DashboardPaths } from 'src/routes/paths';

import { useTranslate } from 'src/locales';

import MajorDonorChatView from 'src/sections/major-donors/major-donor-chat-view';

// ----------------------------------------------------------------------

type Props<T extends DashboardPaths> = {
  title: string;
  activeRole: string;
  paths: T;
};

export default function MajorDonorChat({ title, activeRole, paths }: Props<DashboardPaths>) {
  const { t } = useTranslate();

  const params = useParams();

  const { id } = params;

  return (
    <>
      <Helmet>
        <title>
          {t(title)} - {t(activeRole)}
        </title>
      </Helmet>

      <MajorDonorChatView id={id!} title={title} paths={paths} />
    </>
  );
}
