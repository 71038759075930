export const ar = {
  button: {
    add_evaluator: 'إضافة مقيم',
    view: 'عرض',
    edit_evaluator: 'تحرير المقيم',
    delete_evaluator: 'حذف المقيم',
    approve: 'وافق',
    reject: 'رفض المقيم',
    upload_new_invoice: 'تحميل فاتورة جديدة',
    view_public_profile: 'عرض الملف الشخصي العام',
    back: 'الخلف',
    public_page: 'الصفحة العامة للمقيم',
    profile_requests: 'طلبات الملف الشخصي',
    chat_with_evaluator: 'التحدث إلى المقيم',
    more_options: 'خيارات أخرى',
    add: 'إضافة',
    filter: 'تصفية',
  },
  title: {
    all_evaluation_request: 'كل طلبات التقييم',
    new_evaluation_request: 'طلب تقييم جديد',
    cant_create_more_price: 'مجموع مبالغ السحب اكبر من تكلفة الصيانة',
  },
  form: {
    name: {
      label: 'اسم المقيم',
      required: 'اسم المقيم مطلوب',
    },
    maintenance_campaign_name: {
      label: 'اسم حملة الصيانة ',
      required: 'اسم الحملة مطلوب',
    },
    profile_picture: {
      label: 'تحميل صورة الملف الشخصي',
      required: 'صورة الملف الشخصي مطلوبة.',
    },
    mosque_name: {
      label: 'اسم المسجد',
      required: 'اسم المسجد مطلوب',
    },
    location: {
      label: 'المواقع',
    },
    request_date: {
      label: 'تاريخ الطلب',
    },
    date_added: {
      label: 'تاريخ الإضافة',
    },
    deleted: {
      label: 'محذوف',
    },
    from: {
      label: 'من',
    },
    to: {
      label: 'حتى',
    },
  },
  mosque_form: {
    repair_cost: {
      label: 'تكلفة الصيانة',
      required: 'تكلفة الصيانة مطلوبة',
    },
    cover_image: {
      label: 'صورة الغلاف',
      required: 'صورة الغلاف مطلوبة',
    },
    walls: {
      label: 'جدران',
    },
    structural: {
      label: 'بناء',
    },
    amount: {
      label: 'المبلغ',
      required: 'المبلغ مطلوب',
      min_1: 'يجب أن يكون المبلغ أكبر من 0',
    },
  },
  headers: {
    created_at: 'تاريخ الإضافة',
    condition: 'الحالة',
    maintenance_progress: 'آخر تقدم للصيانة',
    mosque_name: 'اسم المسجد',
    mosque_needs: 'احتياجات المسجد',
    repair_cost: 'تكلفة الصيانة',
    chat_with_super_admin: 'التحدث إلى المسؤول',
    chat_with_evaluator: 'التحدث إلى المقيم',
  },
  tabs: {
    evaluation_requested: 'التقييمات المطلوبة',
    evaluation_finished: 'التقييمات المنتهية',
  },
  details: {
    finished_maintenance: 'الصيانة المنتهية',
    fired_maintenance: 'الصيانة المبدوءة',
    email: 'البريد الإلكتروني ',
  },
};
