export const en = {
  dashboard: {
    label: {
      total_item_types: 'Total project item types',
      total_campaign_types: 'Total campaign types',
      total_maintenance_types: 'Total maintenance request types',
      overalview: 'Overview',
      total_projects: 'Total Projects',
      total_campaigns: 'Total Campaigns',
      total_donors: 'Total Donors',
      total_vendors: 'Total Vendors',
      total_operators: 'Total Operators',
      total_evaluators: 'Total Evaluators',
      total_volunteers: 'Total Volunteers',
      total_money_donated: 'Total Money Donated',
      total_money_paid: 'Total Money Paid',
      total_donation_for_this_project: 'Total Donation for this Project',
      total_money_donated_this_project: 'Total Money Donated for Project’s Campaigns',
      total_people_donated_this_project: 'Total People Donated for this Project',
      total_people_donated_this_project_campaigns: 'Total People Donated for Project’s Campaigns',
      total_campaign_only_money_paid: 'Total Campaign Only Money Paid',
      total_vendor_income_paid: 'Total Vendor Income Paid',
      total_donate_for_item: 'Total Money Donated for this Item',
      total_needed_item: 'Total Needed Item',
      total_provided_item: 'Total Provided Item',
      finished_milestones: 'Finished Milestones',
      ongoing_milestones: 'Ongoing Milestones',
      total_milestones: 'Total Milestones',
      total_request_to_evaluate: 'Request to evaluate',
      mosque_evaluated: 'Mosque Evaluated',
      archive_evaluated: 'Archive Evaluation',
      apply_evaluation: 'Apply Evaluation',
      total_income: 'Total Income',
      finished_campaigns: 'Finished Campaigns',
      ongoing_campaigns: 'Ongoing Campaigns',
      total_amount_donation: 'Total Donations',
      total_campaign: 'Campaigns',
      total_amount_campaign: 'Amount of Campaigns',
      total_project_item: 'Project Items',
      total_amount_project_item: 'Amount of Project Items',
      total_zakat: 'Zakats',
      total_amount_zakat: 'Amount of Zakats',
      charts: {
        donation: 'Donation Chart',
        total_paid_money: 'Total Paid Money Chart',
        vendor_and_only_money: 'Vendor and Only Money Paid Chart',
        total_donation_project: 'Total Donations for Project Chart',
        total_donation_item_project: 'Total Donations for this Item in this Project',
        DAILY: 'Daily',
        WEEKLY: 'Weekly',
        MONTHLY: 'Monthly',
        YEARLY: 'Yearly',
        CAMPAIGN: 'Campaign',
        ITEM: 'Project Item',
        SILVER: 'Silver Zakat',
        GOLD: 'Gold Zakat',
        MONEY: 'Money Zakat',
        TRADE_GOODS: 'Trade Goods Zakat',
        PROJECT: 'Project',
        MOSQUE: 'Mosque Maintenance',
        BALANCE: 'Balance',
        DEPOSIT: 'Deposit',
        PURCASE: 'Donation',
      },
      duration: 'Duration',
      number_of_milestones: 'Number of Milestones',
      type: 'Type',
      milestones: 'Milestones',
      invoices: 'Invoices',
      total_income_until_today: 'Total income until today',
      only_money: 'Only Money',
      popover: {
        description:
          'Campaign only money is the money in which operators pay you to buy stuffs and things for the campaign. this money isnot vendor’s salary and vendor needs to upload any invoices that they buy for the campaign in invoices page.',
      },
      pay_to_vendor: 'Pay to Vendor',
      total_assigned_volunteers: 'Assigned Volunteers',
      total_pending_volunteers: 'Pending Volunteers',
      parent_campaign_name: 'Parent Campaign Name',
      parent_project_name: 'Parent Project Name',
      total_tickets: 'Total Tickets',
      solved_tickets: 'Solved Tickets',
      average_handling: 'Average Handling Time',
      status: 'Status',
      total_contribution: 'Total Contribution',
      currently_campaigns: 'Currently Campaigns',
      total_remaining_amount: 'Total Remaining Amount',
      total_project: 'Total Project',
      total_amount_project: 'Amount of Projects',
    },
  },
};
