export const en = {
  button: {
    view: 'View',
    delete_donor: 'Delete Donor',
    edit_donor: 'Edit Donor',
    profile_request: 'Profile Request',
    donation_history: 'Donation History',
    task_as_volunteer: 'Task as Volunteer',
    view_public_profile: 'View Public Profile',
    change_to_major_donor: 'Change to be Major Donor',
  },
  form: {
    donor_name: {
      label: "Donor's Name",
      required: "Donor's name is required",
    },
    email: {
      label: 'Email',
    },
    date_joined: {
      label: 'Date Joined',
    },
    volunteer_amount: {
      label: 'Volunteer',
    },
    added_portfolio: {
      label: 'Portfolio',
      added: 'Added',
      not_added: 'Not Added',
    },
    type: {
      label: 'Type',
    },
    balance: {
      label: 'Balance',
    },
    verified_by_email: {
      label: 'Verified by email',
    },
    from: {
      label: 'From',
    },
    to: {
      label: 'To',
    },
    search: {
      label: 'Search',
    },
  },
  label: {
    DAILY: 'Daily',
    WEEKLY: 'Weekly',
    MONTHLY: 'Monthly',
    YEARLY: 'Yearly',
    CAMPAIGN: 'Campaign',
    ITEM: 'Project Item',
    SILVER: 'Zakat',
    GOLD: 'Zakat',
    major_donor: 'Major Donor',
    regular_donor: 'Regular Donor',
    main_information: 'Main Information',
    wallet_information: 'Wallet Information',
  },
};
