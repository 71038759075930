/* eslint-disable no-nested-ternary */

import { isAxiosError } from 'axios';
import { useMemo, useState } from 'react';

import { Box, Stack, Alert, Button, Container } from '@mui/material';

import { useRouter } from 'src/routes/hooks';
import { DashboardPaths } from 'src/routes/paths';

import { useTranslate } from 'src/locales';
import { useGetIdeas } from 'src/api/idea';

import Iconify from 'src/components/iconify';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';

import IdeasTableListView from './table-view/idea-table-list-view';

// ----------------------------------------------------------------------

type Props<T extends DashboardPaths> = {
  paths: T;
  title: string;
};

export default function IdeaListView({ title, paths }: Props<DashboardPaths>) {
  const { t } = useTranslate();

  const router = useRouter();

  const [page, setPage] = useState<number>(1);

  const [limit, setLimit] = useState<number>(5);

  const query = useMemo(
    () => ({
      page,
      limit,
      sort_by: 'created_at',
      include_relations: 'CATEGORY',
    }),
    [page, limit]
  );

  const {
    data: ideas,
    isError: ideasError,
    isLoading: ideasLoading,
    isRefetching: ideasRefetching,
  } = useGetIdeas(query);

  return (
    <Container>
      {!!ideasError && (
        <Alert severity="error" onClose={() => router.push('/')}>
          {isAxiosError(ideasError)
            ? ideasError.response?.data.message
              ? ideasError.response.data.message
              : 'Something went wrong'
            : ideasError}
        </Alert>
      )}
      {!ideasError ? (
        <Stack direction="column" spacing={4}>
          <Stack
            direction="row"
            spacing={4}
            component="div"
            justifyContent="space-between"
            alignItems="center"
          >
            <CustomBreadcrumbs
              heading={t(title)}
              links={[
                {
                  name: t('menu.dashboard'),
                  href: paths.statistics,
                },
                { name: t(title) },
              ]}
            />
            <Box>
              <Button
                color="primary"
                variant="contained"
                onClick={() => router.push(paths.ideas.add)}
              >
                <Iconify icon="material-symbols:add-2-rounded" />
                {t('section.idea.button.add_idea')}
              </Button>
            </Box>
          </Stack>

          <IdeasTableListView
            data={ideas}
            paths={paths}
            loading={ideasLoading || ideasRefetching}
            changeCurrentPage={(res) => setPage(res)}
            changeLimit={(res) => setLimit(res)}
          />
        </Stack>
      ) : null}
    </Container>
  );
}
