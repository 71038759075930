export const ar = {
  headers: {
    date_added: 'تاريخ الإضافة',
    date_updated: 'آخر تحديث',
    project: 'المسجد',
  },
  titles: {
    project_land: 'معرض المساجد',
    donation_chart: 'مخطط التبرع',
  },
  button: {
    add_operator: 'إضافة مكتب إدارة المساجد',
    view: 'عرض',
    edit_operator: 'تعديل مكتب إدارة المساجد',
    delete_operator: 'حذف مكتب إدارة المساجد',
    approve: 'تأكيد',
    reject: 'رفض مكتب الإدارة',
    upload_new_invoice: 'تحميل فاتورة جديدة',
    view_public_profile: 'عرض الملف الشخصي العام',
    back: 'الخلف',
    public_page: 'الصفحة العامة لمكتب الإدارة ',
    profile_requests: 'طلبات الملف الشخصي',
  },
  form: {
    mosque_name: {
      label: 'اسم المسجد',
      required: 'اسم المسجد مطلوب',
    },
    region: {
      label: 'منطقة المدير',
      required: 'منطقة المدير مطلوبة',
    },

    vendor_region: {
      label: 'منطقة المقاول',
      required: 'منطقة المقاول مطلوبة',
    },
    evaluator_region: {
      label: 'منطقة المقيم',
      required: 'منطقة المقيم مطلوبة',
    },
    donor_region: {
      label: 'منطقة المانح',
      required: 'منطقة المانح مطلوبة',
    },
    project_region: {
      label: 'منطقة المسجد',
      required: 'منطقة المسجد مطلوبة',
    },
    name: {
      label: 'اسم مكتب إدارة المساجد',
      required: ' اسم مكتب إدارة المساجد مطلوب',
    },
    level_of_access: {
      label: 'مستوى الوصول',
      required: 'مستوى الوصول مطلوب',
    },
    profile_picture: {
      label: 'تحميل صورة الملف الشخصي',
      required: 'صورة الملف الشخصي مطلوب',
    },
    country: {
      label: 'الدولة',
      required: 'الدولة مطلبةب',
    },
    vendor_name: {
      label: 'اسم المقاول',
      required: ' اسم المقاول مطلوب',
    },
    selected_invoice: {
      label: 'حدد فاتورة',
      required: 'تحتاج إلى تحديد الفاتورة',
    },
    reason_to_pay: {
      label: 'سبب الدفع',
      required: 'أنت بحاجة إلى سبب لدفع هذا المقاول',
    },
  },
};
