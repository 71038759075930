import { CurrentUserDTO, CurrentUserDTORolesEnum } from '@otarid/ommar-sdk';

export const getUserForMessaging = (currentUser: CurrentUserDTO, role: CurrentUserDTORolesEnum) => {
  const { full_name, profile_picture_url: initProfilePicture } = currentUser;
  let name: string = full_name;
  let profile_picture_url: string | null = initProfilePicture;

  if (currentUser.donor_details && role === 'DONOR') {
    name = currentUser.donor_details.name ?? '';
    profile_picture_url = currentUser.donor_details.profile_picture_url ?? null;
  }

  if (currentUser.vendor_details && role === 'VENDOR') {
    name = currentUser.vendor_details.name ?? '';
    profile_picture_url = currentUser.vendor_details.profile_picture_url ?? null;
  }

  if (currentUser.operator_details) {
    name = currentUser.operator_details.name ?? '';
    profile_picture_url = currentUser.operator_details.profile_picture_url ?? null;
  }

  if (currentUser.evaluator) {
    name = currentUser.evaluator.name ?? '';
    profile_picture_url = currentUser.evaluator.profile_picture_url ?? null;
  }

  return {
    name,
    profile_picture_url,
  };
};
