export const ar = {
  title: {
    idea: 'فكرة',
    all_ideas: 'كل الأفكار',
    new_idea: 'فكرة جديدة',
    create: 'إنشاء فكرة',
    edit: 'تعديل فكرة',
    something_awesome: 'اكتب شيئاً مثيراً للاهتمام',
    drop_files: 'قم بوضع الملفات هنا أو انقر للتحميل',
  },
  headers: {
    title: 'العنوان',
    description: 'الوصف',
    created_at: 'تاريخ الإنشاء',
    action: 'الإجراء',
  },
  button: {
    view: 'عرض',
    edit_idea: 'تعديل الفكرة',
    delete_idea: 'حذف الفكرة',
    add_idea: 'فكرة جديدة',
  },
  form: {
    title: {
      label: 'العنوان',
      required: 'العنوان مطلوب',
    },
    description: {
      label: 'الوصف',
      required: 'الوصف مطلوب',
    },
    image: {
      label: 'صورة الغلاف',
      required: 'رابط صورة الغلاف مطلوبة',
    },
    files: {
      label: 'ملفات إضافية',
    },
  },
};
