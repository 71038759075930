export const ar = {
  button: {
    add_vendor: 'إضافة المقاول',
    view: 'عرض',
    edit_vendor: 'تحرير المقاول',
    delete_vendor: 'حذف المقاول',
    approve: 'تأكيد',
    reject: 'رفض المقاول',
    upload_new_invoice: 'تحميل فاتورة جديدة',
    steps: {
      one: 'طلباتي',
      two: 'حملاتي',
    },
    filter: 'تصفية',
  },
  form: {
    name: {
      label: 'اسم المقاول',
      required: 'اسم المقاول مطلوب',
    },
    field: {
      label: 'مجال الشركة',
      required: 'مجال الشركة مطلوب',
    },
    type_project_handled: {
      label: 'التخصص',
      required: 'التخصص مطلوب',
    },
    logo: {
      label: 'تحميل شعار الشركة',
      required: 'شعار الشركة مطلوب.',
    },
    similiar_project: {
      label: 'تحميل مسجد مشابه',
      required: 'مسجد مشابه مطلوب.',
    },
    analities: {
      label: 'قم بتحميل أي ملفات تساعدنا على فهم تصنيفاتك بشكل أفضل',
      required: 'ملف التصويري مطلوب',
    },
    date_added: {
      label: 'تاريخ الإضافة',
    },
    email: {
      label: 'البريد الإلكتروني',
    },
    finished_campaigns: {
      label: 'الحملات المنتهية',
    },
    fired_campaigns: {
      label: 'الحملات المطلقة',
    },
    income_status: {
      label: 'حالة الدخل',
    },
    new_vendor_income: {
      label: 'أدخل الميزانية',
      min_1: 'الميزانية يجب أن تكون أكبر من 1',
      required: 'الميزانية مطلوبة',
      max_only_money: 'يجب أن تكون ميزانيتك متساوية أو أقل من',
      previous_budget: 'الميزانية السابقة',
    },
    notes: {
      label: 'ملاحظات',
      required: 'يجب عليك ملء الملاحظات',
    },
    status: {
      label: 'الحالة',
    },
  },
  caption: {
    add_new_edit_request_campaign: 'أضف طلب تحرير حملة جديد',
    old_values: 'القيم القديمة',
    new_values: 'قيم جديدة',
  },
};
