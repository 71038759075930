export const en = {
  title: {
    project_marketplace: 'Project Marketplace',
    status: 'Status',
    tender_request_status: 'Request Status',
    request_detail: 'Request Detail',
    technical_offer: 'Technical Offer',
    financial_offer: 'Financial Offer',
    no_files_uploaded: 'No Files Uploaded',
    cant_create_more_price: `The withdrawals are more than the item's price`,
  },
  button: {
    add_new_item: 'Add New Item',
    submit: 'Submit',
    completion_date: 'Completion date',
    cancel_tender_request: 'Cancel tender request',
    back: 'Back',
    are_you_sure_you_want_to_cancel_tender_request:
      'Are you sure you want to cancel tender request',
    clicking_will_delete_all_info_you_entered:
      'Clicking cancel tender request will erase all the information you enter before!',
    apply_tender_request: 'Apply Tender Request',
    approve_request: 'Approve Request',
    reject_request: 'Reject Request',
    view_detail: 'View Detail',
    filter: 'Filter',
  },
  form: {
    project_item_type: {
      label: 'Project item type',
      required: 'Project item type is required!',
    },
    name: {
      label: "Items's Name",
      required: "Item's name is required!",
    },
    classification: {
      label: "Item's classification",
      required: "Item's classification is required!",
      add: 'Add a new item classification',
    },
    amount_needed: {
      label: 'Total Needed',
      min_1: 'Must be greater than 1',
      required: 'Total needed is required!',
    },
    provide_items: {
      label: 'Provide Items',
      required: 'Provide item is required!',
    },
    price: {
      label: 'Price',
      min_1: 'Must be greater than 1',
      required: 'Price is required!',
    },
    vendor_type: {
      label: 'Select Campaign Type',
      required: 'Vendor type is required!',
      options: {
        MANUAL_VENDOR: 'Manual Vendor',
        WITHOUT_VENDOR: 'Without Vendor',
        PRODUCT_TENDER: 'Product Tender',
      },
    },
    vendor_id: {
      label: 'Select Vendor',
      required: 'Vendor name is required!',
    },
    specification: {
      label: 'Insert the Specification',
      label_2: 'Specification',
      required: 'Specification is required!',
    },
    start_date: {
      label: 'Start Date',
      required: 'Start date is required!',
    },
    project_item_type_id: {
      label: 'Project item type',
      required: 'Project item Type is required',
    },
    deadline_date: {
      label: 'Deadline Date',
      required: 'Deadline date is required!',
    },
    delivery_date: {
      label: 'Delivery Date',
      required: 'Delivery date is required!',
    },
    insurance_details: {
      label: 'Insurance Details',
      required: 'Insurance details is required!',
    },
    contract_agreement: {
      label: 'Contract Agreement',
      required: 'Contract agreement is required!',
      max_1: 'Must be less than 1',
    },
    contract_signature: {
      label: 'Contract Signature',
      required: 'Contract signature is required!',
      max_1: 'Must be less than 1',
    },
    proposed_price: {
      label: 'Proposed Price',
      required: 'Proposed price is required!',
      min_1: 'Must be greater than 1',
    },
    proposed_amount_needed: {
      label: 'Proposed Item Needed',
      required: 'Proposed item needed is required!',
      min_1: 'Must be greater than 1',
    },
    technical_offer: {
      label: 'Technical Offer',
      required: 'Technical Offer is required!',
      max_1: 'Must be less than 1 file',
      max_5: 'Must be less than 5 files',
    },
    financial_offer: {
      label: 'Financial Offer',
      required: 'Financial Offer is required!',
    },
  },
};
