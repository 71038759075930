import { Helmet } from 'react-helmet-async';

import { DashboardPaths } from 'src/routes/paths';

import { useTranslate } from 'src/locales';

import IdeaListView from 'src/sections/idea/view/idea-list-view';

// ----------------------------------------------------------------------

type Props<T extends DashboardPaths> = {
  paths: T;
  title: string;
  activeRole: string;
};

export default function AllIdeasPage({ title, activeRole, paths }: Props<DashboardPaths>) {
  const { t } = useTranslate();

  return (
    <>
      <Helmet>
        <title>
          {t(title)} - {t(activeRole)}
        </title>
      </Helmet>
      <IdeaListView title={title} paths={paths} />
    </>
  );
}
