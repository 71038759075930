import { orderBy } from 'lodash';
import { format } from 'date-fns';
import { isAxiosError } from 'axios';
import { enqueueSnackbar } from 'notistack';
import { IdeaEntity } from '@otarid/ommar-sdk';
import { useState, useEffect, useCallback } from 'react';

import { LoadingButton } from '@mui/lab';
import {
  Card,
  Stack,
  Table,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Typography,
  IconButton,
  TableContainer,
} from '@mui/material';

import { useRouter } from 'src/routes/hooks';
import { DashboardPaths } from 'src/routes/paths';

import { useBoolean } from 'src/hooks/use-boolean';

import axios, { endpoints } from 'src/utils/axios';

import { grey } from 'src/theme/palette';
import { useAuthContext } from 'src/auth/hooks';
import { useLocales, useTranslate } from 'src/locales';

import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { ConfirmDialog } from 'src/components/custom-dialog';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import {
  useTable,
  TableSkeleton,
  getComparator,
  TableEmptyRows,
  TableHeadCustom,
  TablePaginationCustom,
} from 'src/components/table';

import { cutText } from '../../../../utils/strings';

// ----------------------------------------------------------------------

type Props<T extends DashboardPaths> = {
  paths: T;
  loading: boolean;
  data: any | undefined;
  changeCurrentPage: (v: number) => void;
  changeLimit: (v: number) => void;
};

export default function IdeasTableListView({
  data,
  paths,
  loading,
  changeLimit,
  changeCurrentPage,
}: Props<DashboardPaths>) {
  const popover = usePopover();

  const { currentLang } = useLocales();

  const router = useRouter();

  const isLoadingDelete = useBoolean();

  const { user } = useAuthContext();

  const { t } = useTranslate();

  const confirm = useBoolean();

  // const [selectedOwnerId, setSelectedOwnerId] = useState<string>('');
  const [selectedIdeaId, setSelectedIdeaId] = useState<string>('');

  const TABLE_HEAD = [
    { id: 'id', label: '#', align: 'center', width: 75 },
    { id: 'title', label: t('section.idea.headers.title'), align: 'left' },
    { id: 'description', label: t('section.idea.headers.description'), align: 'left' },
    { id: 'created_at', label: t('section.idea.headers.created_at'), align: 'left' },
    { id: 'action', label: t('section.idea.headers.action'), align: 'center' },
  ];

  const table = useTable({
    defaultOrderBy: 'created_at',
    defaultOrder: 'desc',
    defaultRowsPerPage: (data && data.limit) || 5,
  });

  const [direction, setDirection] = useState<'asc' | 'desc'>('desc');

  const [tableData, setTableData] = useState<IdeaEntity[]>([]);

  const handleSortBy = useCallback(
    (newValue: string) => {
      if (direction === 'asc') setDirection('desc');
      else setDirection('asc');
    },
    [direction]
  );

  const dataFiltered = applyFilter({
    inputData: tableData,
    comparator: getComparator(table.order, table.orderBy),
    sortDirection: direction,
  });

  const handleChangePage = useCallback(
    (event: React.MouseEvent<HTMLButtonElement> | null, newValue: number) => {
      changeCurrentPage(newValue + 1);
    },
    [changeCurrentPage]
  );

  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      changeLimit(parseInt(event.target.value, 10));
      changeCurrentPage(0 + 1);
    },
    [changeCurrentPage, changeLimit]
  );

  const handleDeleteIdea = async () => {
    if (selectedIdeaId) {
      const url: string = endpoints.idea.delete(selectedIdeaId);

      const config = {
        headers: {
          ...(user &&
            user.accessToken && {
              Authorization: `Bearer ${user.accessToken}`,
              'x-chosen-role': user?.activeRole,
            }),
        },
      };

      isLoadingDelete.onTrue();

      try {
        const { status } = await axios.delete(url, config);

        if (status === 200) {
          enqueueSnackbar(t('notification.success.deleted_idea'), {
            variant: 'success',
          });

          window.location.reload();
        }
      } catch (error) {
        if (isAxiosError(error)) {
          enqueueSnackbar(
            error.response && error.response.data.message
              ? error.response.data.message[0]
              : error.response?.data,
            {
              variant: 'error',
            }
          );
        } else if (typeof error.message === 'object') {
          const errMessages = error.message;

          errMessages.forEach((m: string) => {
            enqueueSnackbar(m, {
              variant: 'error',
            });
          });
        } else if (error.name) {
          enqueueSnackbar(error.name, {
            variant: 'error',
          });
        } else {
          enqueueSnackbar(error.message, {
            variant: 'error',
          });
        }
      } finally {
        popover.onClose();
        isLoadingDelete.onFalse();
      }
    }
  };

  const denseHeight = table.dense ? 34 : 54;

  useEffect(() => {
    if (!loading && data) {
      table.setRowsPerPage(data.limit);
      setTableData(data.data);
    }
  }, [data, table, loading]);

  return (
    <>
      <Stack direction="column" gap="16px">
        <Card sx={{ boxShadow: 'none', border: `1px solid ${grey[300]}` }}>
          <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
            <Scrollbar>
              <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 800 }}>
                <TableHeadCustom
                  order={table.order}
                  orderBy={table.orderBy}
                  headLabel={TABLE_HEAD}
                  onSort={handleSortBy}
                />

                <TableBody>
                  {loading ? (
                    Array.from({ length: table.rowsPerPage }).map((_, index) => (
                      <TableSkeleton key={index} sx={{ height: denseHeight }} />
                    ))
                  ) : (
                    <>
                      {dataFiltered.length ? (
                        dataFiltered.map((row: any, i: number) => (
                          <TableRow
                            hover
                            key={i}
                            sx={{
                              borderBottom: i !== data.length - 1 ? `1px solid ${grey[300]}` : '',
                            }}
                          >
                            <TableCell align="center">
                              {data && data.currentPage && data.currentPage < 2
                                ? i + 1
                                : table.rowsPerPage * ((data?.currentPage ?? 1) - 1) + i + 1}
                            </TableCell>
                            <TableCell> {row.title || '-'} </TableCell>
                            <TableCell align="left"> {cutText(row.description || '-')} </TableCell>
                            <TableCell align="left">
                              {row.created_at
                                ? format(new Date(row.created_at), 'dd MMM yyyy', {
                                    locale: currentLang.adapterLocale,
                                  })
                                : '-'}
                            </TableCell>
                            <TableCell align="center">
                              <IconButton
                                onClick={(e) => {
                                  // setSelectedOwnerId(row.owner_user_id);
                                  setSelectedIdeaId(row.idea_id);
                                  popover.onOpen(e);
                                }}
                              >
                                <Iconify icon="eva:more-vertical-fill" />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableEmptyRows height={denseHeight} emptyRows={1} />
                      )}
                    </>
                  )}

                  <CustomPopover
                    open={popover.open}
                    onClose={popover.onClose}
                    arrow="right-top"
                    sx={{
                      width: 'auto',
                      bgcolor: 'white',
                      backgroundImage: 'none',
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 1,
                      alignItems: 'flex-start',
                      justifyContent: 'flex-start',
                    }}
                  >
                    <MenuItem
                      onClick={() => {
                        popover.onClose();
                        router.push(paths.ideas.edit(selectedIdeaId));
                      }}
                      sx={{ width: '100%' }}
                    >
                      <Iconify icon="solar:pen-bold" sx={{ mr: '8px !important' }} width={20} />
                      <Typography variant="body2">{t('section.idea.button.edit_idea')}</Typography>
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        confirm.onTrue();
                        popover.onClose();
                      }}
                      disabled={isLoadingDelete.value}
                      sx={{ width: '100%' }}
                    >
                      <Iconify
                        icon="eva:trash-2-outline"
                        sx={{ mr: '8px !important' }}
                        width={20}
                      />
                      <Typography variant="body2">
                        {t('section.idea.button.delete_idea')}
                      </Typography>
                    </MenuItem>
                  </CustomPopover>
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>
        </Card>

        <TablePaginationCustom
          count={(data && data.total) || 0}
          page={(data && data.currentPage - 1) || table.page}
          rowsPerPage={(data && data.limit) || table.rowsPerPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{ bgcolor: '#FCFCFC' }}
        />
      </Stack>
      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        loading={isLoadingDelete.value}
        title={t('form.delete.title')}
        content={t('form.global.are_you_sure')}
        cancel={t('form.delete.cancel_button')}
        action={
          <LoadingButton
            variant="contained"
            color="error"
            onClick={handleDeleteIdea}
            loading={isLoadingDelete.value}
          >
            {t('form.delete.delete_button')}
          </LoadingButton>
        }
      />
    </>
  );
}

function applyFilter({
  inputData,
  comparator,
  sortDirection,
}: {
  inputData: IdeaEntity[];
  comparator: (a: any, b: any) => number;
  sortDirection: string;
}) {
  const stabilizedThis = inputData.map((el, index) => [el, index] as const);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);

    if (order !== 0) return order;

    return a[1] - b[1];
  });

  inputData = stabilizedThis.map((el) => el[0]);

  if (sortDirection === 'asc' || sortDirection === 'desc') {
    inputData = orderBy(inputData, ['updated_at'], [sortDirection]);
  }
  return inputData;
}
