import { isAxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { UserModuleApi, DonorModuleApi, GetDonorStatisticPeriodEnum } from '@otarid/ommar-sdk';

import { workerApisConfiguration } from 'src/utils/worker-configuration';

import { useAuthContext } from 'src/auth/hooks';

import { IQueryProps } from './project';

export function useGetUsers(query: IQueryProps) {
  const { user } = useAuthContext();
  const userApi = new UserModuleApi(workerApisConfiguration);
  const token = sessionStorage.getItem('accessToken') ?? '';

  const result = useQuery({
    queryKey: ['getUsers', { query }],
    queryFn: async () =>
      userApi
        .findMany(query, {
          headers: {
            Authorization: `Bearer ${token}`,
            'x-chosen-role': user?.activeRole,
          },
        })
        .catch((error) => {
          if (isAxiosError(error)) {
            throw new Error(error?.response?.data.message || error.message);
          }

          throw new Error(error.message);
        }),
  });

  return result;
}

export function useGetUser(id: string) {
  const { user } = useAuthContext();
  const token = sessionStorage.getItem('accessToken') ?? '';
  const userApi = new UserModuleApi(workerApisConfiguration);

  const result = useQuery({
    queryKey: ['getUsers', { id }],
    queryFn: async () => {
      const response = await userApi.findUser(
        {
          id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'x-chosen-role': user?.activeRole,
          },
        }
      );

      return response.data.data;
    },
    enabled: Boolean(id || id !== ''),
  });

  return result;
}

export function useGetDonors(query: IQueryProps, enabled: boolean) {
  const { user } = useAuthContext();
  const donorsApi = new DonorModuleApi(workerApisConfiguration);
  const token = sessionStorage.getItem('accessToken') ?? '';

  const result = useQuery({
    queryKey: ['getDonors', { query }],
    queryFn: async () =>
      donorsApi.findManyDonor(
        { ...query },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'x-chosen-role': user?.activeRole,
          },
        }
      ),
    enabled,
  });

  return result;
}

export function useGetDonorDetail(id: string) {
  const { user } = useAuthContext();
  const donorApi = new DonorModuleApi(workerApisConfiguration);
  const token = sessionStorage.getItem('accessToken') ?? '';

  const result = useQuery({
    queryKey: ['getDonorDetail', { id }],
    queryFn: async () => {
      const response = await donorApi.findDonorById(
        {
          donor_id: id,
          include_relations: 'owner_donor',
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'x-chosen-role': user?.activeRole,
          },
        }
      );

      return response.data.data;
    },
    enabled: Boolean(id || id !== ''),
  });

  return result;
}

export function useGetDonorStatistics(query: { id: string; period: GetDonorStatisticPeriodEnum }) {
  const { user } = useAuthContext();
  const donorApi = new DonorModuleApi(workerApisConfiguration);
  const token = sessionStorage.getItem('accessToken') ?? '';

  const result = useQuery({
    queryKey: ['getDonorStatistics', { query }],
    queryFn: async () => {
      const response = await donorApi.getDonorStatistic(
        {
          donor_id: query.id,
          period: query.period,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'x-chosen-role': user?.activeRole,
          },
        }
      );

      return response.data.data;
    },
    enabled: Boolean(query.id || query.id !== '') && !!query.period,
  });

  return result;
}
