export const ar = {
  campaign: {
    edit_request: {
      have_milestone_process: 'لديك عملية للمعلَم المحذوف الخاص بك',
      have_milestone_invoice: 'لديك فاتورة للمعلَم المحذوف الخاص بك',
      have_milestone_invoice_on_process: 'لديك فاتورة واحدة من هذه العملية',
    },
  },
  regions: {
    latitude_exist: 'تم استخدام إحداثيات خط العرض لمكان آخر!',
    longitude_exist: 'تم استخدام إحداثيات خط الطول لمكان آخر!',
  },
  operator: {
    cant_delete_project_in_progress: 'لدى مكتب الإدارة مشاريع مقدمة ',
    cant_delete_campaign_in_progress: 'لدى مكتب الإدارة حملات مقدمة ',
  },
  donor: {
    email_exist: 'الإيميل موجود مسبقاً',
  },
  wallet: {
    email_conflict: 'هذا الايميل متلك محفظة مسبقا',
    name_conflict: 'يوجد محفظة بنفس الاسم مسبقا',
    donor_has_wallet: 'يمتلك هذا المتبرع محفظة مسبقا',
  },
};
