import { ar as conflict } from 'src/locales/exception/409/ar';
import { ar as not_found } from 'src/locales/exception/404/ar';
import { ar as bad_request } from 'src/locales/exception/400/ar';
import { ar as forbidden_exception } from 'src/locales/exception/403/ar';
import { ar as unprocessable_exception } from 'src/locales/exception/422/ar';

export const ar = {
  409: conflict,
  404: not_found,
  400: bad_request,
  403: forbidden_exception,
  422: unprocessable_exception,
};
