import { isAxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { IdeaModuleApi } from '@otarid/ommar-sdk';

import { workerApisConfiguration } from 'src/utils/worker-configuration';

import { useAuthContext } from 'src/auth/hooks';

// ----------------------------------------------------------------------

export type IdeaIQueryProps = {
  page?: number;
  limit?: number;
  sort_by?: string;
  sort_direction?: string;
};

export type IdeaByIdIQueryProps = {
  idea_id: string;
};

// ----------------------------------------------------------------------

export function useGetIdeas(query: IdeaIQueryProps) {
  const { user } = useAuthContext();
  const token = sessionStorage.getItem('accessToken') ?? '';
  const ideaApi = new IdeaModuleApi(workerApisConfiguration);

  const result = useQuery({
    queryKey: ['getIdeas', { query, user }],
    queryFn: async () => {
      const response = await ideaApi
        .getAllIdeas(query, {
          headers: {
            Authorization: `Bearer ${token}`,
            'x-chosen-role': user?.activeRole,
          },
        })
        .catch((error) => {
          if (isAxiosError(error)) {
            throw new Error(error?.response?.data.message || error.message);
          }

          throw new Error(error.message);
        });

      return response.data;
    },
  });

  return result;
}

// ----------------------------------------------------------------------

export function useGetIdeaById(query: IdeaByIdIQueryProps) {
  const { user } = useAuthContext();
  const token = sessionStorage.getItem('accessToken') ?? '';
  const ideaApi = new IdeaModuleApi(workerApisConfiguration);

  const result = useQuery({
    queryKey: ['getIdeaById', { query, user }],
    queryFn: async () => {
      const response = await ideaApi
        .getIdeaById(
          { id: query.idea_id },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'x-chosen-role': user?.activeRole,
            },
          }
        )
        .catch((error) => {
          if (isAxiosError(error)) {
            throw new Error(error?.response?.data.message || error.message);
          }

          throw new Error(error.message);
        });

      return response.data;
    },
    enabled: Boolean(query.idea_id || query.idea_id !== ''),
  });

  return result;
}
