export const ar = {
  button: {
    view: 'عرض',
    delete_donor: 'حذف المانح',
    edit_donor: 'تعديل المانح',
    profile_request: 'طلب الملف الشخصي',
    donation_history: 'تاريخ التبرع',
    task_as_volunteer: 'المهمة كمتطوع',
    view_public_profile: 'عرض الملف الشخصي العام',
    change_to_major_donor: 'التغيير ليكون المانح الرئيسي',
  },
  form: {
    donor_name: {
      label: 'اسم المانح',
      required: 'اسم المانح مطلوب',
    },
    email: {
      label: 'البريد الإلكتروني',
    },
    date_joined: {
      label: 'تاريخ الانضمام',
    },
    volunteer_amount: {
      label: 'متطوع',
    },
    added_portfolio: {
      label: 'مَلَفّ',
      added: 'وأضاف',
      not_added: 'لم يضاف',
    },
    type: {
      label: 'يكتب',
    },
    balance: {
      label: 'توازن',
    },
    verified_by_email: {
      label: 'تم التحقق منه عن طريق الايميل',
    },
    from: {
      label: 'من',
    },
    to: {
      label: 'حتى',
    },
    search: {
      label: 'البحث',
    },
  },
  label: {
    DAILY: 'يومي',
    WEEKLY: 'أسبوعي',
    MONTHLY: 'شهري',
    YEARLY: 'سنوي',
    CAMPAIGN: 'حملة',
    ITEM: 'عنصر المسجد',
    SILVER: 'زكاة الفضة',
    GOLD: 'زكاة الذهب',
    major_donor: 'المانح الرئيسي',
    regular_donor: 'متبرع منتظم',
    main_information: 'المعلومات الرئيسية',
    wallet_information: 'معلومات المحفظة',
  },
};
