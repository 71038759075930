export const ar = {
  volunteer: {
    not_volunteer: 'أنت لست متطوعاً',
  },
  campaign_task: {
    full_request: 'المهمة كاملة/غير متوفرة ، جميع المقاعد التي تم أخذها بالفعل',
    approved_volunteer: 'لقد أصبحت بالفعل متطوعاً في هذه المهمة',
    already_applied: 'لقد تقدمت بالفعل بطلب لهذه المهمة ، يرجى انتظار الموافقة من المسؤول',
    apply_request_not_found: 'لا يمكن العثور على الطلب',
    already_rejected: 'تم رفض هذا الطلب بالفعل',
  },
  project_item: {
    cant_be_updated: 'لا يمكن تعديل العنصر ﻷنه منته!',
  },
  ticket: {
    cannot_assign_ticket: 'لا يمكن تعيين التذكرة',
    already_resolved: 'تم حل التذكرة بالفعل',
  },
  product_tender: {
    not_vendor: 'حسابك غير مسموح له بهذا الطلب',
    item_not_found: 'هذا العنصر غير موجود',
    invalid_item: 'عنصر السوق ليس  من فئات مناقصة المقاول',
    vendor_request_exists: 'لديك طلب موجود',
    vendor_request_not_found: 'لم يتم العثور على طلب المقاول',
    vendor_request_already_approved: 'تمت الموافقة على طلب المقاول ',
    vendor_request_already_rejected: 'تم رفض طلب المقاول ',
  },
  invoice: {
    already_uploaded: 'لقد قمت بالتقدم الى هذه المناقصة من قبل!',
  },
};
