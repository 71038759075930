import { m } from 'framer-motion';
import { useMemo, useEffect } from 'react';
import { formatDistanceToNowStrict } from 'date-fns';

import {
  Box,
  Stack,
  Badge,
  Divider,
  Tooltip,
  MenuItem,
  useTheme,
  IconButton,
  Typography,
} from '@mui/material';

import { useRouter } from 'src/routes/hooks';
import { ROOTS_TYPE, PATHS_DASHBOARD } from 'src/routes/paths';

import { useResponsive } from 'src/hooks/use-responsive';

import { useLocales, useTranslate } from 'src/locales';
import { useSocketTicketContext } from 'src/socket-ticket/hooks';

// import Label from 'src/components/label';

import { isAxiosError } from 'axios';
import { NotificationEntity, NotificationModuleApi } from '@otarid/ommar-sdk';

import { workerApisConfiguration } from 'src/utils/worker-configuration';

import { UserRoleType } from 'src/auth/types';
import { useAuthContext } from 'src/auth/hooks';
import { useGetNotifications } from 'src/api/notification';

import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { varHover } from 'src/components/animate';
import CustomPopover, { usePopover } from 'src/components/custom-popover';

// ----------------------------------------------------------------------

export default function NotificationsPopover() {
  const { t } = useTranslate();
  const { currentLang } = useLocales();

  const theme = useTheme();

  const popover = usePopover();

  const { user } = useAuthContext();

  let role: string = user?.activeRole.toLowerCase();

  if (role === UserRoleType.CLUSTER_ADMIN) {
    role = 'admin';
  }

  const roleType: ROOTS_TYPE = role.toUpperCase() as ROOTS_TYPE;

  const paths = PATHS_DASHBOARD(roleType);

  const router = useRouter();

  const { socket, handleJoinTicketRoom, listenIncomingMessage, listenNewNotification } =
    useSocketTicketContext();

  const handleMarkAllAsRead = () => {
    const notificationsApi = new NotificationModuleApi(workerApisConfiguration);
    const token = sessionStorage.getItem('accessToken') ?? '';
    const response = notificationsApi
      .update(
        {
          body: {},
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'x-chosen-role': user?.activeRole,
          },
        }
      )
      .then(() => {
        refetchNotifications();
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          throw new Error(error?.response?.data.message || error.message);
        }

        throw new Error(error.message);
      });

    return response;
  };

  const handleReadNotification = (id: string) => {
    const notificationsApi = new NotificationModuleApi(workerApisConfiguration);
    const token = sessionStorage.getItem('accessToken') ?? '';
    notificationsApi
      .markOneAsRead(
        {
          NotificationMarkOneAsReadDto: {
            notification_id: id,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'x-chosen-role': user?.activeRole,
          },
        }
      )
      .then(() => {
        refetchNotifications();
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          throw new Error(error?.response?.data.message || error.message);
        }

        throw new Error(error.message);
      });
  };

  const { data: notifications, refetch: refetchNotifications } = useGetNotifications(true, {
    limit: 0,
    page: 1,
  });

  const smUp = useResponsive('up', 'sm');

  const totalUnRead = useMemo(
    () => notifications?.data.filter((item) => !item.read_status).length,
    [notifications]
  );

  const renderHead = (
    <Stack direction="row" alignItems="center" sx={{ px: 1, py: 0.5 }}>
      <Typography
        variant="body1"
        sx={{ flexGrow: 1, fontWeight: theme.typography.fontWeightSemiBold }}
      >
        {t('section.ticket.caption.notifications')}
      </Typography>

      {!!totalUnRead && (
        <Tooltip title="Mark all as read">
          <IconButton color="primary" size="small" onClick={handleMarkAllAsRead}>
            <Iconify icon="eva:done-all-fill" />
          </IconButton>
        </Tooltip>
      )}

      {!smUp && (
        <IconButton onClick={popover.onClose}>
          <Iconify icon="mingcute:close-line" />
        </IconButton>
      )}
    </Stack>
  );

  const onItemClick = (notification: any) => {
    handleReadNotification(notification.id);
    if (['TICKET', 'TICKETING'].includes(notification.notif_type)) {
      handleJoinTicketRoom(notification.room_id);

      router.push(paths.tickets.detail_ticket(notification.ticket_id!));
    }

    if (['APPLY_REQUEST'].includes(notification.notif_type)) {
      if (role === UserRoleType.EVALUATOR) {
        router.push(paths.maintenance_request.my_evaluation_task);
      } else {
        router.push(paths.maintenance_request.evaluator_maintenance_requests);
      }
    }

    if (['VENDOR_REQUEST'].includes(notification.notif_type)) {
      if (role === UserRoleType.VENDOR) {
        router.push(paths.campaigns.my_campaigns);
      } else {
        router.push(paths.campaigns.detail_vendor_request(notification.vendor_request_id || ''));
      }
    }

    if (['NEW_MILESTONE_PROCESS'].includes(notification.notif_type)) {
      const campaign_id = notification.redirect_url?.split('/')[3];
      const vendor_id = notification.redirect_url?.split('/')[5];

      router.push(
        `${paths.campaigns.detail_campaign(campaign_id)}/vendors/${vendor_id}/progresses`
      );

      // router.push(paths.campaigns.vendor.milestones.all_progress(campaign_id, vendor_id));
    }

    if (notification.specific_type === 'NEW_DONATION_CAMPAIGN') {
      const payment_history = notification.redirect_url?.split('/').pop();

      router.push(
        payment_history
          ? `${paths.campaigns.transaction_list_campaign(payment_history)}`
          : paths.campaigns.all_campaign
      );
    }

    if (
      notification.specific_type === 'NEW_DONATION_PROJECT' ||
      notification.specific_type === 'NEW_DONATION_MOSQUE'
    ) {
      const payment_history = notification.redirect_url?.split('/').pop();

      router.push(
        payment_history
          ? `${paths.projects.detail_project(payment_history)}`
          : paths.projects.all_project
      );
    }

    if (notification.notif_type === 'NEW_PROJECT') {
      const project_id = notification.redirect_url?.split('/').pop();

      router.push(
        project_id ? paths.projects.detail_project(project_id) : paths.projects.all_project
      );
    }

    if (notification.notif_type === 'NEW_CAMPAIGN') {
      const campaign_id = notification.redirect_url?.split('/').pop();

      if (notification.specific_type === 'SEND_BACK_CAMPAIGN_REQUEST') {
        router.push(paths.campaigns.send_back_requests.root);
      } else {
        router.push(
          campaign_id ? paths.campaigns.detail_campaign(campaign_id) : paths.campaigns.all_campaign
        );
      }
    }

    if (notification.notif_type === 'NEW_PROJECT_ITEM') {
      const item_id = notification.redirect_url?.split('/').pop();

      router.push(item_id ? paths.marketplace.detail_item(item_id) : paths.marketplace.all_item);
    }

    if (notification.notif_type === 'NEW_MILESTONE_PAYMENT') {
      const campaign_id = notification.redirect_url?.split('/')[3];

      router.push(
        campaign_id
          ? paths.campaigns.vendor.payment_history_progress.root(campaign_id)
          : paths.campaigns.my_campaigns
      );
    }

    if (notification.notif_type === 'NEW_CAMPAIGN_VENDOR') {
      router.push(
        notification.specific_type === 'NEW_CAMPAIGN_VENDOR_RESPOND_REQUEST'
          ? paths.campaigns.my_campaigns
          : paths.campaigns.detail_vendor_request(notification.vendor_request_id || '')
      );
    }
    popover.onClose();
  };

  const getIcon = (notification: NotificationEntity): string => {
    if (notification.specific_type === 'TICKET_CLOSED') return 'solar:ticket-bold-duotone';

    if (notification.specific_type === 'TICKET_RESOLVED')
      return 'solar:clipboard-check-bold-duotone';

    if (notification.specific_type === 'NEW_TICKET') return 'solar:ticket-sale-bold-duotone';

    if (notification.specific_type === 'FORWARDED_TICKET')
      return 'solar:square-forward-bold-duotone';

    if (notification.specific_type === 'ASSIGNED_TICKET')
      return 'solar:clipboard-text-bold-duotone';

    if (notification.notif_type === 'NEW_MILESTONE_PAYMENT') return 'solar:banknote-2-bold-duotone';

    if (notification.notif_type === 'NEW_DONATION') return 'solar:wad-of-money-bold-duotone';

    if (
      ['NEW_PROJECT', 'NEW_CAMPAIGN', 'NEW_PROJECT_ITEM', 'NEW_CAMPAIGN_VENDOR'].includes(
        notification.notif_type
      )
    )
      return 'solar:buildings-bold-duotone';

    return 'solar:chat-line-bold-duotone';
  };

  useEffect(() => {
    if (socket) {
      listenNewNotification();
      listenIncomingMessage();
    }
  }, [socket, listenNewNotification, listenIncomingMessage]);

  return (
    <>
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        color={popover.open || totalUnRead! > 0 ? 'primary' : 'default'}
        style={{ backgroundColor: popover.open ? theme.palette.primary.lighter : undefined }}
        onClick={popover.onOpen}
      >
        <Badge badgeContent={totalUnRead} color="primary">
          <Iconify icon="solar:bell-linear" width={24} />
        </Badge>
      </IconButton>

      <CustomPopover open={popover.open} onClose={popover.onClose} hiddenArrow>
        {renderHead}

        <Divider sx={{ mt: 0.25, mb: 0.5 }} />

        <Box
          sx={{
            height:
              !notifications?.data.length ||
              (notifications?.data.length > 0 && notifications?.data.length < 4)
                ? 'auto'
                : 3.068 * 83,
            width: 325,
          }}
        >
          <Scrollbar>
            <Box sx={{ width: 325 }}>
              {notifications?.data.length ? (
                notifications.data.map((notification, i) => (
                  <MenuItem key={i} onClick={() => onItemClick(notification)}>
                    <Stack component="div" direction="column" spacing={0.25}>
                      <Stack component="div" direction="row" spacing={1} alignItems="flex-start">
                        <Iconify
                          width={22}
                          color={notification.read_status ? 'inherit' : 'secondary.main'}
                          icon={getIcon(notification)}
                          sx={{
                            mt: 0.3,
                            mr: '0 !important',
                          }}
                        />
                        <Stack
                          component="div"
                          direction="column"
                          spacing={0.5}
                          sx={{ maxWidth: 325, minWidth: 100 }}
                        >
                          <Typography
                            variant="body2"
                            sx={{ wordWrap: 'break-word', whiteSpace: 'normal' }}
                          >
                            {t(notification.title)}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="text.disabled"
                            sx={{ wordWrap: 'break-word', whiteSpace: 'normal' }}
                          >
                            {notification.content}
                          </Typography>
                          <Typography variant="caption" color="text.disabled">
                            {formatDistanceToNowStrict(new Date(notification.created_at), {
                              addSuffix: true,
                              locale: currentLang.adapterLocale,
                            })}
                          </Typography>
                        </Stack>
                      </Stack>
                    </Stack>
                    {!notification.read_status && (
                      <Box
                        sx={{
                          top: 15,
                          width: 6,
                          height: 6,
                          right: 20,
                          borderRadius: '50%',
                          bgcolor: 'secondary.main',
                          position: 'absolute',
                        }}
                      />
                    )}
                  </MenuItem>
                ))
              ) : (
                <Typography variant="caption" sx={{ px: 1, mb: 1 }}>
                  {t('caption.empty_notif')}
                </Typography>
              )}
            </Box>
          </Scrollbar>
        </Box>
      </CustomPopover>
    </>
  );
}
