import * as Yup from 'yup';
import { isAxiosError } from 'axios';
import { useForm } from 'react-hook-form';
import { IdeaEntity } from '@otarid/ommar-sdk';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMemo, useEffect, useCallback } from 'react';

import LoadingButton from '@mui/lab/LoadingButton';
import { Card, Grid, Stack, Typography } from '@mui/material';

import { useRouter } from 'src/routes/hooks';
import { DashboardPaths } from 'src/routes/paths';

import { useBoolean } from 'src/hooks/use-boolean';

import axios, { endpoints } from 'src/utils/axios';

import { useTranslate } from 'src/locales';
import { useAuthContext } from 'src/auth/hooks';

import { useSnackbar } from 'src/components/snackbar';
import FormProvider, { RHFUpload, RHFTextField } from 'src/components/hook-form';

import { ASSETS_API } from '../../../config-global';

// ----------------------------------------------------------------------

type Props<T extends DashboardPaths> = {
  paths: T;
  currentIdea?: IdeaEntity;
};

export default function IdeaEditView({ currentIdea, paths }: Props<DashboardPaths>) {
  const router = useRouter();
  const { t } = useTranslate();
  const { user } = useAuthContext();

  const { enqueueSnackbar } = useSnackbar();

  const preview = useBoolean();

  const editIdeaSchema = Yup.object().shape({
    title: Yup.string().required(t('section.idea.form.title.required')),
    description: Yup.string().required(t('section.idea.form.description.required')),
    image: Yup.mixed<any>().nullable().optional(),
    files: Yup.array().of(Yup.mixed<any>().nullable()).optional(),
  });

  const defaultValues = useMemo(
    () => ({
      title: currentIdea?.title || '',
      description: currentIdea?.description || '',
      image: currentIdea?.image?.url ? `${ASSETS_API}/${currentIdea?.image?.url}` : null,
      files: [],
    }),
    [currentIdea]
  );

  const methods = useForm({
    resolver: yupResolver(editIdeaSchema),
    defaultValues,
  });

  const {
    reset,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
    watch,
  } = methods;

  const formValues = watch();

  useEffect(() => {
    if (currentIdea) {
      reset(defaultValues);
    }
  }, [currentIdea, defaultValues, reset]);

  const onSubmit = handleSubmit(async (data) => {
    try {
      const formData = new FormData();
      const { image, files, ...othersData } = data;

      Object.entries(othersData).forEach(([key, value]) => {
        formData.append(key, value);
      });

      if (image && typeof image !== 'string') {
        formData.append('image', image);
      }

      for (let i = 0; i < (files?.length ?? 0); i += 1) {
        formData.append(`files`, files![i]);
      }

      const { status } = await axios.patch(endpoints.idea.edit(currentIdea!.idea_id), formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${user?.accessToken}`,
          'x-chosen-role': user?.activeRole,
        },
      });

      if (status === 200) {
        enqueueSnackbar(t('notification.success.edit_idea'), {
          variant: 'success',
        });
        reset();
        preview.onFalse();

        router.push(paths.ideas.all);
      }
    } catch (error) {
      if (isAxiosError(error)) {
        enqueueSnackbar(
          error.response && error.response.data.message
            ? error.response.data.message[0]
            : error.response?.data,
          {
            variant: 'error',
          }
        );
      }
    }
  });

  const handleDropImage = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];

      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });

      if (file) {
        setValue('image', newFile, { shouldValidate: true });
      }
    },
    [setValue]
  );

  const handleRemoveImage = useCallback(() => {
    setValue('image', null);
  }, [setValue]);

  const handleDropFiles = useCallback(
    (acceptedFiles: File[]) => {
      setValue('files', acceptedFiles, { shouldValidate: true });
    },
    [setValue]
  );

  const handleRemoveFile = useCallback(
    (file: File | string) => {
      setValue('files', formValues.files?.filter((f) => f !== file));
    },
    [setValue, formValues.files]
  );

  const renderDetails = (
    <Grid item container spacing={3}>
      <Grid item xs={12}>
        <Card>
          <Stack spacing={3} sx={{ p: 3 }}>
            <RHFTextField
              name="title"
              label={t('section.idea.form.title.label')}
              disabled={isSubmitting}
            />

            <RHFTextField
              name="description"
              label={t('section.idea.form.description.label')}
              multiline
              rows={3}
              disabled={isSubmitting}
            />

            <Stack spacing={1.5}>
              <Typography variant="subtitle2"> {t('section.idea.form.image.label')}</Typography>
              <RHFUpload
                name="image"
                maxSize={3145728}
                onDrop={handleDropImage}
                disabled={isSubmitting}
                onDelete={handleRemoveImage}
                fieldName={t('section.idea.title.drop_files')}
              />
            </Stack>

            <Stack spacing={1.5}>
              <Typography variant="subtitle2"> {t('section.idea.form.files.label')}</Typography>
              <RHFUpload
                name="files"
                maxSize={3145728}
                onDrop={handleDropFiles}
                disabled={isSubmitting}
                accept={{
                  'image/*': [],
                  'application/pdf': [],
                }}
                fieldName={t('section.idea.title.drop_files')}
                showViewButton={false}
                onRemove={handleRemoveFile}
                multiple
              />
            </Stack>
          </Stack>
        </Card>
      </Grid>
    </Grid>
  );

  const renderActions = (
    <Grid item xs={12} md={6} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
      <LoadingButton
        type="submit"
        color="info"
        variant="contained"
        size="large"
        loading={isSubmitting}
        sx={{ ml: 2, width: '100%' }}
      >
        {!currentIdea ? t('section.idea.button.add_idea') : t('section.idea.button.edit_idea')}
      </LoadingButton>
    </Grid>
  );

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Grid container spacing={3}>
        {renderDetails}
        {renderActions}
      </Grid>
    </FormProvider>
  );
}
