export const ar = {
  WITHOUT_VENDOR: 'بدون مقاول',
  MANUAL_VENDOR: 'مع مقاول',
  PRODUCT_TENDER: 'طرح كمناقصة',
  campaign_specification_add_successfully: 'تم انشاء تصنيف جديد للحملات بنجاح!',
  item_specification_add_successfully: 'تم انشاء تصنيف جديد للعناصر بنجاح!',
  maintenance_specification_add_successfully: 'تم انشاء تصنيف جديد لحملات الصيانة بنجاح!',
  add_successfully: 'تم انشاء هدف جديد للحملات بنجاح!',
  add_new_campaign_specification: 'انشاء تصنيف جديد للحملات',
  add_new_campaign_goal: 'اضافة هدف جديد للحملات',
  vendor: {
    label: 'اسم المقاول',
    required: 'اسم المقاول مطلوب',
  },
  dialog: {
    body: 'اسم الهدف',
    specification_name: 'اسم التصنيف',
  },
  title: {
    campaign_milestone: 'إنجاز الحملة',
    add_milestone: 'أضف معلَم',
    anonymous_donor: 'مانح مجهول',
    donor: 'مانحين',
    is_on_the_way: 'المنجز',
    pending: 'قيد الانتظار',
    completed: 'مكتمل',
    milestone_details: 'تفاصيل معلَم',
    new_progress: 'طلب جديد',
    progresses: 'المراحل المنجزة',
    new_invoice: 'أضف فاتورة جديدة',
    detail_invoice: 'تفاصيل الفاتورة',
    enter_reply: 'إضافة رد',
    cant_create_more_price: 'مجموع مبالغ السحب اكبر من سعر الحملة',
    filter: 'تصفية',
  },
  conditions: {
    pending: 'قيد الانتظار',
    approved: 'معتمدة',
    published: 'منشورة',
    rejected: 'مرفوضة',
    finished: 'منتهية',
  },
  button: {
    add_progress_process: 'أضف مرحلة إنجاز',
    add_milestone: 'إضافة معلَم',
    add_new_campaign: 'أضف حملة جديدة',
    submit_milestone: 'إرسال معلَم رئيسي',
    edit_campaign: 'تحرير الحملة',
    request_submit_campaign: ' تأكيد الحملة',
    request_submit_item: ' إرسال',
    save_campaign: 'حفظ الحملة',
    apply_for_campaign: 'تقدم بطلب للحصول على هذه الحملة',
    accept_this_vendor: 'قبول هذا المقاول',
    reject_this_vendor: 'رفض هذا المقاول',
    chat_with_super_admin: 'التحدث إلى المسؤول',
    chat_with_vendor: 'التحدث إلى المقاول',
    chat_with_operator: 'التحدث إلى مكتب الإدارة',
    send: 'ارسال',
    edit_reply: 'تعديل',
    delete_reply: 'حذف',
    milestones: {
      view_invoices: 'عرض الفواتير',
      add_progress: 'تقدم بطلب',
      view: 'عرض المعلَم',
      close_view: 'تأكيد',
      submit_progress: 'تأكيد ',
      cancel: 'إلغاء',
      check_progress: 'التحقق من الطلب',
      view_progress: 'عرض ',
      view_progresses: 'عرض ',
      confirm_progress: 'تأكيد ',
      reject_progress: 'رفض ',
      submit_invoice: 'إرسال الفاتورة',
      view_payment: 'عرض الدفع',
    },
    request_still_pending: 'هذا الطلب معلق',
    filter: 'تصفية',
  },
  form: {
    campaign_goal_id: {
      required: 'هدف الحملة مطلوب',
    },
    milestone_date: {
      every_day: 'كل يوم',
      every_week: 'كل أسبوع',
      every_month: 'كل شهر',
      every_six_month: 'كل ستة أشهر',
      every_year: 'كل عام',
    },
    name: {
      label: 'اسم الحملة',
      required: 'اسم الحملة مطلوب',
    },
    start_date: {
      cant: 'تاريخ البدء لا يمكن ان يكون بعد تاريخ الموعد النهائي!',
    },
    description: {
      label: 'ادخل الوصف',
      required: 'الوصف مطلوب',
    },
    project_id: {
      label: 'حدد المسجد ',
      required: ' المسجد مطلوب',
    },
    condition: {
      label: 'الحالة',
    },
    delivery_date_gt: {
      label: 'موعد التسليم بعد',
    },
    delivery_date_lt: {
      label: 'موعد التسليم قبل',
    },
    delivery_date: {
      label: 'موعد التسليم',
      required: 'موعد التسليم مطلوب',
      updated_at: 'آخر تحديث',
    },
    campaign_type_id: {
      label: 'نوع الحملة',
      required: 'تصنيف الحملة مطلوب',
    },
    campaign_classification_id: {
      label: 'تصنيف الحملة',
      required: 'تصنيف الحملة مطلوب',
    },
    campaign_progress: {
      label: 'ارسال تقدم الحملة',
      required: 'تقدم الحملة مطلوب',
    },
    campaign_goal: {
      label: 'الهدف من الحملة',
      required: 'هدف الحملة مطلوب',
    },
    requested_amount: {
      label: 'المبلغ المطلوب',
      required: 'المبلغ المطلوب مطلوب',
      min_1: 'المبلغ  يجب أن يكون أكبر من 0',
    },
    vendor_income: {
      add: 'أضف دخل المقاول',
      label: 'دخل المقاول',
      required: 'مطلوب دخل المقاول',
      min_1: 'يجب أن يكون دخل المقاول أكبر من 0',
      max_only_money: 'يجب أن يكون دخل المقاول متساوياً أو أقل من مبلغ المال فقط',
    },
    images: {
      label: 'اسحب و افلت الملفات هنا للرفع',
      required: 'تحميل الملفات مطلوب',
      max_files: 'مسموح تحميل 3 ملفات فقط',
    },
    milestone_name: {
      label: 'اسم المعلَم',
      required: 'اسم الحدث الرئيسي مطلوب',
    },
    process_name: {
      label: 'اسم التقدم',
      required: 'اسم التقدم مطلوب',
    },
    deadline: {
      label: 'موعد التسليم',
      required: 'الموعد النهائي مطلوب',
    },
    details: {
      label: 'التفاصيل',
      required: 'التفاصيل مطلوبة',
    },
    progress_title: {
      label: 'عنوان التقدم',
      required: 'عنوان التقدم مطلوب',
    },
    milestone: {
      label: 'عدد المراحل',
    },
    type: {
      label: 'نوع التبرع',
    },
    amount: {
      label: 'المبلغ',
    },
    transaction_code: {
      label: 'رمز العملية',
    },
    date: {
      label: 'التاريخ',
    },
    donor_name: {
      label: 'اسم المانح',
    },
    donation: {
      label: 'هبة',
    },
    counts: {
      label: 'العدد',
    },
    email: {
      label: 'البريد الإلكتروني',
    },
    vendor_name: {
      label: 'اسم المقاول',
    },
    invoice_name: {
      label: 'اسم الفاتورة',
      required: 'اسم الفاتورة مطلوب',
    },
    invoice_amount: {
      label: 'المبلغ',
      min_1: 'يجب أن يكون أكبر من 1',
      required: 'المبلغ مطلوب',
      remaining_max: 'يجب أن يكون المبلغ الخاص بك أقل من',
    },
    bank_id: {
      label: 'اسم البنك',
      required: ' اسم البنك مطلوب',
    },
    bank_account_name: {
      label: 'اسم الحساب المصرفي',
      required: 'اسم الحساب المصرفي مطلوب',
    },
    bank_account_number: {
      label: 'رقم الحساب المصرفي',
      required: 'رقم الحساب المصرفي مطلوب',
      min: 'يجب أن يكون رقم الحساب المصرفي مؤلفاً من 22 رقماً ',
    },
    button: {
      reset_filter: 'إعادة تعيين التصفية',
      apply: 'بحث',
    },
    from: {
      label: 'من',
    },
    to: {
      label: 'حتى',
    },
  },
  options: {
    donors: {
      started_from: 'بدأت من',
      ends_to: 'انتهت ب ',
      money_donated: ' الأموال المتبرع بها',
      count_donated: 'إحصائيات التبرع',
    },
  },
};
