export const ar = {
  ticket: {
    ticket_created: 'تم إنشاء تذكرة جديدة',
    new_message: 'رسالة تذكرة جديدة',
    ticket_closed: 'تذكرة مغلقة',
    ticket_forwarded: 'تذكرة جديدة معروفة',
    new_applied_request_message: 'رسالة طلب تطبيقي جديد',
    ticket_assigned: 'تذكرة جديدة تم تعيينها',
    ticket_resolved: 'تذكرة حل',
  },
  donation: {
    donation_received: 'تم استلام تبرع جديد',
  },
  apply_request: {
    new_message: 'رسالة جديدة على طلب تقديم للمقاول',
  },
  project: {
    created_project_to_operator: 'تم إنشاء مسجد جديد',
    need_approval_from_operator: 'المسجد بحاجة إلى موافقة',
    respond: {
      approved_project: 'المسجد المعتمد',
      rejected_project: 'المسجد مرفوض',
    },
    ask: {
      changes: 'تم طلب تغييرات المسجد',
    },
  },
  campaign: {
    created_campaign_to_operator: 'تم إنشاء حملة جديدة',
    need_approval_from_operator: 'الحملة تحتاج إلى موافقة',
    respond: {
      approved_campaign: 'معتمدة الحملة',
      rejected_campaign: 'رفضت الحملة',
    },
    ask: {
      changes: 'تغييرات الحملة المطلوبة',
    },
    vendor_request: 'تطبيق البائع الجديد طلب الحملة',
    vendor_request_approved: 'طلب حملة تطبيق جديد مقبول',
    vendor_request_rejected: 'طلب حملة تطبيق جديد تم رفضه',
  },
  project_item: {
    created_project_item_to_operator: 'تم إنشاء عنصر السوق الجديد',
    need_approval_from_operator: 'عنصر السوق بحاجة إلى موافقة',
    assign_vendor: 'عنصر السوق الجديد المعين لك',
    respond: {
      approved_project_item: 'عنصر السوق المعتمد',
      rejected_project_item: 'عنصر السوق رفض',
    },
    ask: {
      changes: 'تغييرات عنصر السوق المطلوبة',
    },
  },
  milestone: {
    payment: {
      upload_receipt: 'إيصال دفع المعلم الجديد',
    },
    process: {
      new_process: 'عملية معلم جديد',
      accept_request: 'طلب جديد لعملية القبول',
      reject_request: 'طلب جديد لرفض عملية معلم',
    },
  },
};
