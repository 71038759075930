export const en = {
  button: {
    add_evaluator: 'Add Evaluator',
    view: 'View',
    edit_evaluator: 'Edit Evaluator',
    delete_evaluator: 'Delete Evaluator',
    approve: 'Approve',
    reject: 'Reject Evaluator',
    upload_new_invoice: 'Upload New Invoice',
    view_public_profile: 'View Public Profile',
    back: 'Back',
    public_page: `Evaluator's Public Page`,
    profile_requests: 'Profile Requests',
    chat_with_evaluator: 'Chat with evaluator',
    more_options: 'More options',
    add: 'Add',
    filter: 'Filter',
  },
  title: {
    all_evaluation_request: 'All Evaluation Request',
    new_evaluation_request: 'New Evaluation Request',
    cant_create_more_price: `The withdrawals are more than the repair cost`,
  },
  form: {
    name: {
      label: "Evaluator's Name",
      required: "Evaluator's name is required",
    },
    profile_picture: {
      label: 'Upload profile picture',
      required: 'Profile picture is required',
    },
    mosque_name: {
      label: 'Mosque’s Name',
      required: 'Mosque’s Name is required',
    },
    maintenance_campaign_name: {
      label: 'Campaign name',
      required: 'Campaign name is required',
    },
    location: {
      label: 'Locations',
    },
    request_date: {
      label: 'Request Date',
    },
    date_added: {
      label: 'Date added',
    },
    deleted: {
      label: 'Is deleted',
    },
    from: {
      label: 'From',
    },
    to: {
      label: 'To',
    },
  },
  mosque_form: {
    repair_cost: {
      label: 'Repair cost',
      required: 'Repair cost is required',
    },
    cover_image: {
      label: 'Cover image',
      required: 'Cover image is required',
    },
    walls: {
      label: 'Walls',
    },
    structural: {
      label: 'Structural',
    },
    amount: {
      label: 'Amount',
      required: 'Amount is required!',
      min_1: 'Amount must be greater than 0!',
    },
  },
  headers: {
    created_at: 'Date added',
    condition: 'Condition',
    maintenance_progress: 'Last Maintenance Progress',
    mosque_name: 'Mosque name',
    mosque_needs: 'Mosque needs',
    repair_cost: 'Repair cost',
    chat_with_super_admin: 'Chat with super admin',
    chat_with_evaluator: 'Chat with evaluator',
  },
  tabs: {
    evaluation_requested: 'Evaluation Requested',
    evaluation_finished: 'Finished Evaluation',
  },
  details: {
    finished_maintenance: 'Finished Maintenance',
    fired_maintenance: 'Fired Maintenance',
    email: 'Email',
  },
};
