export const ar = {
  title: {
    nearby_places: 'الأماكن المجاورة',
    add_nearby_place: 'إضافة مكان مجاور',
    action: 'الإجراء',
    last_update: 'أخر تحديث',
    created_at: 'تاريخ الإنشاء',
    condition: 'الحالة',
    filter: 'تصفية',
    sort_by: 'ترتيب حسب',
    oldest: 'أقدم',
    newest: 'أحدث',
  },
  link: {
    step1: 'الخطوة 1',
    step2: 'الخطوة 2',
    getting_location: 'الحصول على موقعك',
  },
  form: {
    name: {
      label: 'اسم المسجد',
      required: 'اسم المسجد مطلوب',
    },
    operator: {
      label: 'حدد مكتب الإدارة',
      required: 'الرجاء تحديد مكتب الإدارة',
    },
    address: {
      label: 'العنوان ',
      required: 'العنوان مطلوب',
    },

    description: {
      label: 'الوصف',
      required: 'الوصف مطلوب',
    },
    start_at: {
      label: 'تاريخ البدء',
      required: 'تاريخ البدء مطلوب',
    },
    end_at: {
      label: 'تاريخ الإنتهاء',
      required: 'تاريخ الإنتهاء مطلوب',
    },
    country: {
      label: 'موقعك',
      required: 'موقعك مطلوب',
    },
    cover: {
      label: 'تحميل الصورة المصغرة',
      required: 'الصورة المصغرة مطلوبة',
    },
    thumbnail: {
      label: 'تحميل صور المسجد',
      required: 'صور المسجد مطلوبة',
    },
    diameter: {
      label: 'المساحة',
      required: 'المساحة مطلوبة',
      min_1: 'يجب أن يكون الرقم أكبر من 1',
    },
    prayer_capacity: {
      label: 'سعة الصلاة',
      required: 'سعة الصلاة مطلوبة',
      min_1: 'يجب أن يكون الرقم أكبر من 1',
      from: 'من',
      to: 'ل',
    },
    number_of_toilet: {
      label: 'عدد دورات المياه',
      required: 'عدد دورات المياه مطلوب',
      min_1: 'يجب أن يكون الرقم أكبر من 1',
    },
    has_ac: {
      label: 'يوجد به تكييف',
      required: 'حالة التكييف مطلوبة',
      yes: 'نعم',
      no: 'لا',
    },
    has_classroom: {
      label: 'يوجد به فصول دراسية',
      required: 'مطلوب فئة',
      yes: 'نعم',
      no: 'لا',
    },
    has_parking: {
      label: 'يوجد به موقف سيارات',
      required: 'مطلوب موقف سيارات',
      yes: 'نعم',
      no: 'لا',
    },
    has_green_space: {
      label: 'يوجد به مساحة خضراء',
      required: 'مطلوب مساحة خضراء',
      yes: 'نعم',
      no: 'لا',
    },
    has_female_section: {
      label: 'يوجد به قسم للنساء',
      required: 'مطلوب قسم نسائي',
      yes: 'نعم',
      no: 'لا',
    },
    nearby_place: {
      required: 'يجب اضافة اماكن مجاورة',
    },
    place_name: {
      label: 'اسم المكان',
      required: 'اسم المكان مطلوب',
    },
    place_type: {
      label: 'نوع المكان',
      required: 'نوع المكان مطلوب',
    },
    distance: {
      label: 'المسافة',
      required: 'المسافة مطلوبة',
    },
    hospital: {
      label: 'مشفى',
    },
    school: {
      label: 'مدرسة',
    },
    restaurant: {
      label: 'مطعم',
    },
    region: {
      label: 'المنطقة',
      required: 'المنطقة المحيطة مطلوبة',
    },
    governorate: {
      label: 'المحافظة',
      required: 'مطلوب المحافظة',
    },
    button: {
      next: 'التالي',
      reset: 'اعادة الضبط',
      back: 'الخلف',
      add_nearby_place: 'إضافة مكان مجاور',
      submit_project: 'تأكيد',
      submit_place: 'إضافة المكان',
      save_changes: 'حفظ التغييرات',
      apply: 'بحث',
      reset_filter: 'إعادة تعيين التصفية',
      approve_edit: 'موافقة',
      reject_edit: 'رفض',
      request_submit_project: 'طلب تأكيد المسجد',
      save_project: 'تأكيد ',
    },
  },
  label: {
    details: {
      properties: 'تفاصيل اضافية',
      nearby_places: 'الأماكن المجاورة',
      diameters: 'المساحة',
      prayers_capacity: 'عدد المصلين',
      wc: 'مرحاض',
      ac_condition: 'التكييف',
      classroom: 'قاعة الدراسة',
      parking: 'موقف سيارات',
      green_spaces: 'المساحات الخضراء',
      female_section: 'قسم الإناث',
      yes: 'نعم',
      no: 'لا',
    },
  },
};
