export const ar = {
  all: {
    title: 'الجميع',
  },
  search: 'بحث',
  dashboard: 'لوحة التحكم',
  statistics: 'إحصائيات',
  project: {
    title: 'المساجد',
    all_project: 'كل المساجد',
    add_project: 'إضافة مسجد',
    my_projects: 'مساجدي',
    details: 'تفاصيل المسجد',
    edit: 'تحرير المسجد',
    edit_request: 'طلب تعديل المسجد',
    edit_project: 'تحرير المسجد',
    delete_project: 'حذف المسجد',
    project_requests: 'طلبات المساجد',
    project_discussion: 'مناقشة المسجد',
  },
  campaign: {
    title: 'الحملات',
    all_campaign: 'كل الحملات',
    add_campaign: 'إضافة حملة جديدة',
    all_product_tender_campaign: 'جميع طلبات مناقصات المقاولين',
    my_campaigns: 'حملاتي',
    edit_campaign: 'تحرير الحملة',
    payment_history: 'سجل الدفعات',
    task_volunteers: 'المهمة والمتطوعين',
    new_ticket: 'تذكرة جديدة',
    delete_campaign: 'حذف الحملة',
    campaign_discussion: 'مناقشة الحملة',
    latest_comments: 'أحدث التعليقات',
    anonymous_user: 'مجهول',
    no_comments: 'لا يوجد تعليقات',
    reply: 'رد',
    delete: 'حذف',
    details: 'تفاصيل الحملة',
    edit_request: 'طلب تحرير الحملة',
    donor_list_campaign: 'قائمة المانحين للحملة',
    donor_list_project: 'قائمة المانحين للمسجد',
    vendor_list_campaign: 'قائمة الحملات',
    vendor_favorites_campaign: 'الحملات المفضلة',
    status: 'حالة الحملة',
    vendor_requests: 'طلبات المقاولين',
    detail_vendor_request: 'طلبات المقاولين التفصيلية',
    vendor_lists: 'قوائم المقاولين',
    chat_with_vendor: 'الدردشة مع المقاول',
    send_back_requests: 'الطلبات المعادة',
    send_it_back_to: 'طلب تعديل الدخل',
    detail_send_back_requests: 'إرسال تفاصيل طلبات العودة',
  },
  marketplace: {
    title: 'المتجر',
    all_item: 'كل المنتجات',
    my_item: 'منتجاتي',
    add_item: 'إضافة عنصر',
    details: 'تفاصيل عنصر المسجد',
    edit_item: 'تعديل العنصر',
    edit_request_item: 'تحرير الطلب',
    donor_list_item: 'قائمة المانحين لعناصر المسجد',
    product_tender: 'المناقصات ',
    detail_product_tender: 'تفاصيل المناقصات ',
    detail_product_tender_request: 'تقديم طلب المناقصة',
    all_vendor_tender_request: 'جميع طلبات مناقصات المقاولين ',
    detail_vendor_tender_request: 'طلبات مناقصة المقاول التفصيلية',
  },
  donors: {
    title: 'المانحين',
    add_donor: 'إضافة مانح',
    all_donor: 'جميع المانحين',
    donor_overview: 'نظرة عامة على المانحين',
    update_donor: 'تعديل المانح',
    major_donors: 'المانحون الرئيسيون',
    major_donor_requests: 'طلبات المانحين الرئيسيين',
    detail_major_donor_request: 'تفصيل طلب المانحين الرئيسيين',
  },
  vendors: {
    title: 'المقاولون',
    all_vendor: 'كل المقاولون',
    vendor_requests: 'طلبات المقاولون',
    add_vendor: 'إضافة مقاول جديد',
    detail_vendor: 'تفاصيل المقاول',
    detail_requests_vendor: 'تفاصيل طلب المقاول',
    milestone_progress: 'المراحل المنجزة',
    milestone_invoices: 'فواتير تقدم المعلم',
    payment_history_progress: ' سجل الدفعات',
    edit: 'تحرير المقاول',
    add_send_back_request_campaign: 'أرسل مرة أخرى إلى مكتب الإدارة',
    nonprofit_organizations: 'الجمعيات والشركات غير الربحية',
  },
  operators: {
    title: 'مكاتب إدارة المساجد',
    all_operator: 'مكاتب إدارة المساجد',
    add_operator: 'إضافة مكتب إدارة المساجد',
    update_operator: 'تحديث مكتب إدارة المساجد',
    detail_operator: 'تفاصيل مكتب إدارة المساجد',
  },
  evaluators: {
    title: 'المقيمون',
    maintenance_requests: `تقارير الصيانة للمقيمين`,
    evaluators_request: `طلب المقيم`,
    all_evaluator: 'كل المقيمين',
    add_evaluator: 'إضافة مقيم',
    update_evaluator: 'تعديل مقيم',
    details: `معلومات المقيم`,
    chat_with_super_admin: 'التحدث إلى المسؤول',
    chat_with_evaluator: 'التحدث إلى المقيِّم',
  },
  mosques: {
    add_mosque: 'إضافة مسجد جديد للصيانة',
    all_mosque_directories: 'جميع أدلة المسجد',
    detail_mosque_directories: 'تفاصيل دليل المسجد',
    add_directories: 'إضافة دليل المسجد',
  },
  tickets: {
    title: 'التذاكر',
    all_ticket: 'كل التذاكر',
    detail_ticket: 'تفاصيل التذكرة',
  },
  directories: 'أدلة المساجد',
  maintenance_request: 'حملة طلب صيانة',
  maintenance_requests: 'حملات طلب صيانة',
  community: 'مجتمع',
  blogs: {
    title: 'المدونات',
  },
  items: 'المنتجات',
  request: 'طلب',
  more_options: 'المزيد من الخيارات',
  select_your_options: 'حدد خياراتك',
  operator_page: 'صفحة مكتب الإدارة',
  campaign_public_page: 'الصفحة العامة للحملة',
  parent_project_page: 'صفحة المسجد الأساسي',
  project_public_page: 'الصفحة العامة للمسجد',
  logout: 'تسجيل خروج',
  settings: {
    title: 'الإعدادات',
    account_info: 'معلومات الحساب',
    security_settings: 'إعدادات الأمان',
    my_cards: 'بطاقاتي',
    public_profile: 'إعدادات الملف الشخصي العام',
    profile_settings: 'إعدادات الملف الشخصي',
    delete_account: 'حذف الحساب',
  },
  emails: {
    title: 'المساعدة',
    details: 'تفاصيل الإيميل',
  },
  entity_area: {
    title: 'المناطق الإدارية',
    governorates: 'المناطق الإدارية',
    regions: 'المناطق',
  },
  major_donors: {
    title: 'كبار المانحين',
    all_major_donors: 'جميع المانحين الرئيسيين',
    add_major_donor: 'أضف كبار المانح',
    overview: 'نظرة عامة على المانحين',
    chat_with_major_donor: 'التحدث الى المتبرع الكبير',
  },
  ideas: {
    title: 'بنك الأفكار',
  },
};
