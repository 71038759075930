import { formatDistanceToNowStrict } from 'date-fns';

import { Box, Stack, Avatar, Typography } from '@mui/material';

import { useTranslate } from 'src/locales';
import { ASSETS_API } from 'src/config-global';
import { useAuthContext } from 'src/auth/hooks';
import { TSocketMessageEntity } from 'src/socket-ticket/type';

// ----------------------------------------------------------------------

type Props = {
  index: number;
  message: TSocketMessageEntity;
  messagesLength: number;
};

export default function MajorDonorChatMessageItem({ index, message, messagesLength }: Props) {
  const { t } = useTranslate();

  const { user } = useAuthContext();

  const { sender_id, content, updated_at, profile_picture_url, sender_name, sender_role } = message;

  const renderInfo = (
    <Stack
      direction={
        user && user.id === sender_id && user.activeRole === sender_role ? 'row' : 'row-reverse'
      }
      spacing={1.1}
    >
      <Avatar
        src={
          profile_picture_url
            ? `${ASSETS_API}/${profile_picture_url}`
            : user && user.id === sender_id && user.activeRole === sender_role
            ? user.profile_picture_url
              ? `${ASSETS_API}/${user.profile_picture_url}`
              : undefined
            : undefined
        }
        sx={{
          border: (theme) => `solid 2px ${theme.palette.primary.main}`,
          bgcolor: (theme) => theme.palette.grey[200],
          '& .MuiSvgIcon-root': {
            fill: (theme) => theme.palette.text.disabled,
          },
          maxWidth: 40,
        }}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 0.15,
          alignItems: 'flex-start',
          mb: 1,
          ...(user && (user.id !== sender_id || user.activeRole !== sender_role)
            ? {
                direction: 'rtl',
              }
            : {
                direction: 'ltr',
              }),
        }}
      >
        <Typography
          variant="subtitle2"
          sx={{
            color: (theme) => theme.palette.primary.main,
            fontWeight: (theme) => theme.typography.fontWeightBold,
          }}
        >
          {t(`roles.${sender_role}`)}
        </Typography>
        <Typography variant="caption">{sender_name}</Typography>
      </Box>
    </Stack>
  );

  const renderBody = (
    <Stack
      direction="column"
      spacing={0.5}
      sx={{
        py: 1,
        px: 1.5,
        minWidth: 48,
        maxWidth: 340,
        borderRadius: 1,
        // typography: 'body2',
        bgcolor: 'background.neutral',
      }}
    >
      <Typography
        sx={{
          color: (theme) => theme.palette.text.disabled,
          lineHeight: '20px',
          fontWeight: 400,
          fontSize: 12,
          wordWrap: 'break-word',
          whiteSpace: 'normal',
        }}
      >
        {content}
      </Typography>
      <Typography
        sx={{
          color: (theme) => theme.palette.primary.main,
          fontWeight: (theme) => theme.typography.fontWeightSemiBold,
          lineHeight: '20px',
          fontSize: 11,
          flexShrink: 0, // Added to prevent shrinking
        }}
      >
        {formatDistanceToNowStrict(new Date(updated_at), {
          addSuffix: true,
        })}
      </Typography>
    </Stack>
  );

  return (
    <Stack direction="column" sx={{ mb: 5 }} spacing={0.5}>
      {renderInfo}

      <Stack
        direction="row"
        justifyContent={
          user && (user.id !== sender_id || user.activeRole !== sender_role) ? 'flex-end' : 'unset'
        }
        sx={{
          position: 'relative',
          ...(user && user.id === sender_id && user.activeRole === sender_role
            ? {
                ml: 6,
              }
            : {
                mr: 6,
              }),
        }}
      >
        {renderBody}
      </Stack>
    </Stack>
  );
}
