import { useQuery } from '@tanstack/react-query';
import { CurrentUserDTO, NotificationModuleApi } from '@otarid/ommar-sdk';

import { workerApisConfiguration } from 'src/utils/worker-configuration';

import { useAuthContext } from 'src/auth/hooks';

// ----------------------------------------------------------------------

export type IQueryProps = {
  page?: number;
  limit?: number;
  sort_by?: string;
  sort_direction?: string;
  include_relations?: string;
  author_id?: string;
  user?: CurrentUserDTO;
};

export type BlogByIdIQueryProps = {
  blog_id: string;
  include_relations?: string;
};

// ----------------------------------------------------------------------

export function useGetNotifications(mine: boolean, query: IQueryProps) {
  const { user } = useAuthContext();
  const notificationsApi = new NotificationModuleApi(workerApisConfiguration);
  const token = sessionStorage.getItem('accessToken') ?? '';

  const result = useQuery({
    queryKey: ['getNotifications', { mine, query }],
    queryFn: async () => {
      if (mine) {
        return (
          await notificationsApi.getAllnotifications(query, {
            headers: {
              Authorization: `Bearer ${token}`,
              'x-chosen-role': user?.activeRole,
            },
          })
        ).data;
      }

      return (
        await notificationsApi.getAllnotifications(query, {
          headers: {
            Authorization: `Bearer ${token}`,
            'x-chosen-role': user?.activeRole,
          },
        })
      ).data;
    },
  });

  return result;
}

// ----------------------------------------------------------------------
