/* eslint-disable import/no-duplicates */

import * as Yup from 'yup';
import { format } from 'date-fns';
import { useForm } from 'react-hook-form';
import { useMemo, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { CurrentUserDTORolesEnum, EvaluatorApplyRequestEntity } from '@otarid/ommar-sdk';

import { LoadingButton } from '@mui/lab';
import {
  Box,
  Card,
  Stack,
  Alert,
  Skeleton,
  Typography,
  CardHeader,
  CardContent,
  CardActions,
} from '@mui/material';

import { useGetUser } from 'src/api/user';
import { useTranslate } from 'src/locales';
import { useAuthContext } from 'src/auth/hooks';
import { useGetTicketMessages } from 'src/api/ticket';
import { useGetRoomParticipants } from 'src/api/room';
import { ChatSocketMessageEntity } from 'src/socket-chat/type';
import { useSocketTicketContext } from 'src/socket-ticket/hooks';

// import { useSnackbar } from 'src/components/snackbar';
import FormProvider, { RHFTextField } from 'src/components/hook-form';

import { getUserForMessaging } from 'src/sections/ticket/hooks/ticket-message';

import MajorDonorChatMessageList from '../major-donor-chat-message-list';

// ----------------------------------------------------------------------

type Props = {
  request: EvaluatorApplyRequestEntity | undefined;
};

export default function MajorDonorCardConversation({ request }: Props) {
  const { t } = useTranslate();

  const { user } = useAuthContext();

  const participants = useGetRoomParticipants(request?.room_id || '');

  const participantId = participants?.find((u) => u.user_id !== user?.id)?.user_id;

  const { data: userParticipant } = useGetUser(participantId || '');

  const { socket, conversations, handleInitMessage, handleSendMessage, listenIncomingMessage } =
    useSocketTicketContext();

  // const { enqueueSnackbar } = useSnackbar();

  const {
    data: messages,
    error: messagesError,
    isLoading: messagesLoading,
    isRefetching: messageIsRefething,
  } = useGetTicketMessages(request ? request.room_id : '');

  const SendMessageShcema = Yup.object().shape({
    message: Yup.string().required(t('section.ticket.form.message.required')),
  });

  const defaultValues = {
    message: '',
  };

  const methods = useForm({
    resolver: yupResolver(SendMessageShcema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      await new Promise((resolve) => setTimeout(resolve, 2000));

      if (request) {
        handleSendMessage({
          room_id: request.room_id,
          content: data.message,
        });
      }

      await new Promise((resolve) => setTimeout(resolve, 2000));
    } catch (error) {
      console.error(error);
    } finally {
      reset();
      // enqueueSnackbar('Update success!');
    }
  });

  useEffect(() => {
    if (socket && socket.connected) {
      listenIncomingMessage();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket, listenIncomingMessage]);

  useEffect(() => {
    if (request && messages) {
      const initMessage: ChatSocketMessageEntity[] = messages.map((v) => ({
        message_id: v.message_id,
        content: v.content,
        sender_id: v.sender_id,
        sender_name: v.sender
          ? getUserForMessaging(v.sender, v.sender_role! as CurrentUserDTORolesEnum).name
          : '-',
        profile_picture_url: v.sender
          ? getUserForMessaging(v.sender, v.sender_role! as CurrentUserDTORolesEnum)
              .profile_picture_url
          : null,
        created_at: v.created_at,
        updated_at: v.updated_at,
        room_id: v.room_id,
        room_discussion_type:
          v.room_chat && v.room_chat.room_discussion_type ? v.room_chat.room_discussion_type : '',
        sender_role: v.sender_role
          ? v.sender_role
          : v.sender && v.sender.roles.length
          ? v.sender.roles[0]
          : 'CLUSTER_ADMIN',
      }));

      handleInitMessage(request.room_id, initMessage);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages, request]);

  const messageValues = useMemo(() => {
    if (request && conversations.length) {
      const findingSelectedConversation = conversations.find((v) => v.room_id === request.room_id);

      return findingSelectedConversation ? findingSelectedConversation.messages : [];
    }

    return [];
  }, [request, conversations]);

  return (
    <>
      {!!messagesError && <Alert severity="error">{messagesError.message}</Alert>}

      {!messagesError ? (
        <Card
          variant="elevation"
          sx={{
            bgcolor: '#f6f6f6',
          }}
        >
          <CardHeader
            component={() => (
              <Stack
                spacing={2}
                component="div"
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ p: 2.5 }}
              >
                {messagesLoading || messageIsRefething || !request ? (
                  <>
                    <Skeleton sx={{ width: 1, height: 20 }} />
                    <Skeleton sx={{ width: 1, height: 20 }} />
                  </>
                ) : (
                  <>
                    <Typography sx={{ fontWeight: (theme) => theme.typography.fontWeightSemiBold }}>
                      {userParticipant && userParticipant.operator_details
                        ? userParticipant.operator_details.name
                        : userParticipant?.name ?? '-'}
                      {/* {`${userParticipant?.first_name || ''} ${
                        userParticipant?.middle_name || ''
                      } ${userParticipant?.last_name || ''}`.trim()} */}
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                      <Typography variant="body2" sx={{ color: '#B6B6B6' }}>
                        {format(request ? new Date(request.created_at) : new Date(), 'dd MMM yyyy')}
                      </Typography>
                    </Box>
                  </>
                )}
              </Stack>
            )}
          />

          <CardContent
            sx={{
              py: 0,
              height: 400,
              bgcolor: 'white',
              overflow: 'hidden',
              borderTop: '2px solid #D6D7DA',
            }}
          >
            <Stack
              sx={{
                height: 1,
                overflow: 'hidden',
              }}
            >
              <MajorDonorChatMessageList
                loading={messagesLoading || messageIsRefething}
                messages={messageValues}
              />
            </Stack>
          </CardContent>

          <FormProvider methods={methods} onSubmit={onSubmit}>
            <CardActions
              sx={{
                bgcolor: 'white',
                p: 2.5,
                gap: 2,
                alignItems: 'flex-start',
                flexDirection: 'row',
              }}
            >
              <RHFTextField
                fullWidth
                type="text"
                size="small"
                name="message"
                placeholder={t('section.ticket.form.message.label')}
                disabled={isSubmitting || messagesLoading || messageIsRefething}
              />
              <LoadingButton
                type="submit"
                variant="contained"
                loading={isSubmitting}
                disabled={messagesLoading || messageIsRefething}
                color="primary"
              >
                {t('section.ticket.button.send_message')}
              </LoadingButton>
            </CardActions>
          </FormProvider>
        </Card>
      ) : null}
    </>
  );
}
