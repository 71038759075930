import { Grid, Stack, Container, Typography } from '@mui/material';

import { DashboardPaths } from 'src/routes/paths';

import { useTranslate } from 'src/locales';
import { useAuthContext } from 'src/auth/hooks';

// import TicketCardConversation from '../card/ticket-card-conversation';
import MajorDonorCardConversation from './card/major-donor-card-conversation';
// ----------------------------------------------------------------------

type Props<T extends DashboardPaths> = {
  id: string;
  paths: T;
  title: string;
};

export default function MajorDonorChatView({ id, title, paths }: Props<DashboardPaths>) {
  // const { currentLang } = useLocales();

  const { user } = useAuthContext();

  const { t } = useTranslate();

  // const { request_id } = useParams();

  // const {
  //   data: requestDetails,
  //   isLoading: detailsLoading,
  //   error: detailsError,
  // } = useGetApplyRequestById(request_id ?? '');

  // const onChat = async () => {
  //   const roomChatApi = new RoomChatModuleApi(workerApisConfiguration);

  //   isLoadingChat.onTrue();

  //   try {
  //     // if (detailRequest?.data.data.room_id) {
  //     //   handleJoinTicketRoom(detailRequest.data.data.room_id);

  //     //   await new Promise((resolve) => setTimeout(resolve, 2000));

  //     //   router.push(paths.campaigns.chat_for_vendor_request(id || ''));
  //     // } else {
  //     const room = await roomChatApi.create(
  //       {
  //         RoomChatCreateDto: {
  //           admin_id: user?.id,
  //           room_discussion_type: 'VENDOR_REQUEST',
  //           room_topic_id: id || '',
  //           room_topic_table: '',
  //           client_id: '',
  //           // detailRequest?.data.data.vendor?.owner_user_id ||
  //           user_role: 'VENDOR',
  //         },
  //       },
  //       {
  //         headers: {
  //           Authorization: `Bearer ${user?.accessToken}`,
  //           'x-chosen-role': user?.activeRole,
  //         },
  //       }
  //     );

  //     handleJoinTicketRoom(room.data.data.room_id);

  //     await new Promise((resolve) => setTimeout(resolve, 2000));

  //     router.push(paths.campaigns.chat_for_vendor_request(id || ''));
  //     // }
  //   } catch (error) {
  //     enqueueSnackbar(error.message, {
  //       variant: 'error',
  //     });
  //   } finally {
  //     isLoadingChat.onFalse();
  //   }
  // };

  return (
    <Container>
      {/* {!!detailsError && <Alert severity="error">{detailsError.message}</Alert>} */}

      <Stack spacing={4} direction="column">
        {/* {!detailsLoading && requestDetails ? (
            <Box>
              <Button
                onClick={() => router.back()}
                variant="soft"
                startIcon={
                  <Iconify
                    color="secondary.main"
                    icon={
                      currentLang.value === 'ar'
                        ? 'eva:arrow-ios-forward-fill'
                        : 'eva:arrow-ios-back-fill'
                    }
                  />
                }
              >
                {t('section.project.form.button.back')}
              </Button>
            </Box>
          ) : (
            <Stack
              spacing={4}
              direction={{ xs: 'column', md: 'row' }}
              component="div"
              alignItems={{ xs: 'flex-start', md: 'center' }}
              justifyContent="space-between"
            >
              <Stack component="div" spacing={2} direction="column">
                <Skeleton sx={{ width: 250, height: 35 }} />
                <Skeleton sx={{ width: 400, height: 35 }} />
              </Stack>
              <Stack component="div" spacing={2} direction="row">
                <Skeleton sx={{ width: 125, height: 35 }} />
              </Stack>
            </Stack>
          )} */}

        <Typography variant="h4" gutterBottom>
          {user?.activeRole === 'DONOR'
            ? t('menu.evaluators.chat_with_super_admin')
            : t('menu.major_donors.chat_with_major_donor')}
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} md={7}>
            <MajorDonorCardConversation
              request={
                // requestDetails?.data ??
                undefined
              }
            />
          </Grid>
        </Grid>
      </Stack>
    </Container>
  );
}
