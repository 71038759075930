export const en = {
  title: 'Volunteers',
  caption: {
    all_volunteer: 'All Volunteer',
    detail_volunteer: 'Volunteer Details',
    contact_us: 'Contact Us',
    analities: 'Your analities files',
    campaign_task_for_volunteer: 'Campaign tasks for Volunteers',
    all_campaign_task: 'All Campaign Tasks',
    detail_campaign_task: 'Detail Campaign Task',
    edit_campaign_task: 'Edit Campaign Task',
    volunteer_reports: 'Volunteer Reports',
  },
  button: {
    add_new_task: 'Add New Task',
    submit_task: 'Submit Task',
    edit_campaign_task: 'Edit Task',
  },
  form: {
    name: {
      label: 'Volunteer Name',
      required: 'Volunteer name is required!',
    },
    request_campaign: {
      label: 'Request Campaign',
    },
    approve_campaign: {
      label: 'Approve Campaign',
    },
    job: {
      label: 'Job',
      required: 'Job is required!',
    },
    abilities: {
      label: 'Your abilities',
      required: 'Your abilities is required!',
      max: 'Maximum 500 characters',
    },
    about_free_time: {
      label: 'Tell us about your free time in your days',
      required: 'This field is required!',
    },
    how_to_find: {
      label: 'How did find Ommar',
      required: 'This field is required!',
    },
    country: {
      label: 'Country',
      required: 'Country is required!',
    },
    region: {
      label: 'Region',
      required: 'Region is required!',
    },
    governorate: {
      label: 'Governorate',
      required: 'Governorate is required!',
    },
    selection: {
      options: {
        advertisements: 'Advertisements',
        event_or_conferences: 'Work-related events or conferences',
        referal_industry: 'Referral from someone in the industry',
        online_platforms: 'Online platforms',
        other: 'Other',
      },
    },
    analities: {
      max: 'Maximum 3 files!',
    },
    address: {
      label: 'Your location',
    },
    task_name: {
      label: "Task's Name",
      required: "Task's Name is required!",
    },
    assigned_volunteers: {
      label: 'Assigned Volunteers',
      required: 'Assigned Volunteers is required!',
    },
    requested_volunteers: {
      label: 'Requested Volunteers',
      required: 'Requested Volunteers is required!',
    },
    campaign_id: {
      label: 'Parent Campaign',
      required: 'Parent Campaign is required!',
    },
    available_seats: {
      label: 'Available Seats',
    },
    enrollment_type: {
      label: 'Enrollment Type',
      required: 'Enrollment Type is required!',
      options: {
        self_enrolled: 'Self-Enrolled',
        auto_enrolled: 'Auto-Enrolled',
        invited_enrollment: 'Invited Enrollment',
        mandatory_enrollment: 'Mandatory Enrollment',
        optin_enrollment: 'Opt-In Enrollment',
        conditional_enrollment: 'Conditional Enrollment',
        group_enrollment: 'Group Enrollment',
        referral_enrollment: 'Referral Enrollment',
      },
    },
    gender: {
      label: 'Gender',
      min_1: 'Choose at least one option',
      options: {
        male: 'Male',
        female: 'Female',
      },
    },
    reason_to_pay: {
      label: 'Reason to Pay',
      required: 'You must fill the reason to Pay!',
    },
    is_remote: {
      label: 'It is Remote?',
      required: 'You must select one choice!',
      yes: 'Yes',
      no: 'No',
    },
    is_urgent: {
      label: 'It is Ergent?',
    },
    available_disabilities: {
      label: 'Available for Disabilities?',
    },
    needs_interview: {
      label: 'Needs Interview?',
    },
    task_details: {
      label: 'Task Details',
      required: 'Task Details is required!',
      max: 'Maximum 500 characters!',
    },
    required_skills: {
      label: 'Required Skills',
      required: 'Required Skills is required!',
    },
    required_profession: {
      label: 'Required Profession',
      required: 'Required Profession is required!',
    },
    support_provider: {
      label: 'Support Provider',
      required: 'Support Provider is required!',
    },
    required_age: {
      label: 'Required Age',
      required: 'Required Age is required!',
    },
    benefits: {
      label: 'Benefits for You',
      required: 'Benefits is required!',
    },
    date_assigned: {
      label: 'Date Assigned',
    },
    date_requested: {
      label: 'Date Requested',
    },
    task_done_before: {
      label: 'Task Done Before',
    },
  },
};
