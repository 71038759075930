export const en = {
  headers: {
    date_added: 'Date added',
    date_updated: 'Last update',
    project: 'Project',
  },
  titles: {
    project_land: 'Project land',
    donation_chart: 'Donation chart',
  },
  button: {
    add_operator: 'Add Operator',
    view: 'View',
    edit_operator: 'Edit Operator',
    delete_operator: 'Delete Operator',
    approve: 'Approve',
    reject: 'Reject Operator',
    upload_new_invoice: 'Upload New Invoice',
    view_public_profile: 'View Public Profile',
    back: 'Back',
    public_page: `Operator's Public Page`,
    profile_requests: 'Profile Requests',
  },
  form: {
    mosque_name: {
      label: "Mosque's Name",
      required: "Mosque's Name is required",
    },
    region: {
      label: "Operator's Region",
      required: "Operator's Region is required",
    },
    vendor_region: {
      label: "Vendor's Region",
      required: "Vendor's Region is required",
    },
    evaluator_region: {
      label: "Evaluator's Region",
      required: "Evaluator's Region is required",
    },
    donor_region: {
      label: "Donor's Region",
      required: "Donor's Region is required",
    },
    project_region: {
      label: "Project's Region",
      required: "Project's Region is required!",
    },
    name: {
      label: "Operator's Name",
      required: "Operator's name is required",
    },
    level_of_access: {
      label: 'Level of access',
      required: 'Level of access is required',
    },
    profile_picture: {
      label: 'Upload profile picture',
      required: 'Profile picture is required',
    },
    country: {
      label: 'Country',
      required: 'Country is required',
    },
    vendor_name: {
      label: 'To Vendor',
      required: 'Vendor name is required',
    },
    selected_invoice: {
      label: 'Select Invoice',
      required: 'You need select on of Invoice',
    },
    reason_to_pay: {
      label: 'Reason to Pay',
      required: 'You need a reason for pay this Vendor',
    },
  },
};
