export const ar = {
  mosque_maintenance: {
    name: 'اسم حملة الصيانة',
  },
  caption: {
    all_request: 'جميع حملات طلبات الصيانة',
    detail_request: 'تفاصيل حملة طلب صيانة',
    card_detail: {
      location: 'الموقع',
      description: 'الوصف',
      overal_rates: 'التقييمات الإجمالية',
      submitter: 'معلومات المقدم',
    },
    attachments: 'المرفقات',
    photos: 'صور',
    project_approved: 'المسجد المعتمد',
    maximum_evaluator: 'الرجاء إدخال أقصى تقييم للمسجد',
    evaluator_assigned: 'تم تعيين المقيِّم',
    project_status: 'حالة المسجد',
    all_evaluation_request: 'جميع طلبات التقييم',
    detail_evaluation_request: 'تفاصيل طلب التقييم',
    evaluation: 'تقييم',
    project_evaluated: 'تقييم المسجد',
    my_evaluation_task: 'مهمتي التقييمية',
    step: 'الخطوة',
    all_mosques_evaluated: 'تقييمات جمع المساجد',
    detail_mosques_evaluated: 'تفاصيل تقييم المسجد',
    no_name_mosque: '(لا اسم مسجد)',
    general: 'عام',
    location_pinpoint: 'موقع الخريطة',
    saudi_government: 'الحكومة السعودية',
  },
  form: {
    evaluator_for_project: {
      label: 'المقيم للمسجد',
      placeholder: 'على سبيل المثال 10 مقيِّم',
      required: 'يجب عليك إدخال الحد الأقصى للمقيم',
      min: 'الرجاء إدخال المقيِّم أكبر من 0',
    },
    mosque_name: {
      label: 'اسم المسجد',
      required: 'اسم المسجد مطلوب',
    },
    locations: {
      label: 'الموقع',
    },
    request_date: {
      label: 'تاريخ الطلب',
    },
    evaluate_date: {
      label: 'تقييم التاريخ',
    },
    selection: {
      label: 'حدد الخيار الخاص بك',
      required: 'يجب عليك تحديد أحد الخيارات',
      options: {
        very_poor: 'فقير جدا (1-2)',
        poor: 'فقير (3-4)',
        fair: 'معرض (5-6)',
        good: 'جيد (7-8)',
        excellent: 'ممتاز (9-10)',
        necessary: 'ضروري',
        unnecessary: 'غير ضروري',
        very_weak: 'ضعيف جدا',
        weak: 'ضعيف',
        needs_improvement: 'يحتاج الى تحسينات',
      },
    },
    image: {
      label: 'تحميل الصورة',
      required: 'يجب عليك إضافة صورة واحدة على الأقل',
    },
    prayer_hall: {
      label: 'قاعة الصلاة',
    },
    wet_areas: {
      label: 'المناطق الرطبة',
    },
    minaret: {
      label: 'مئذنة',
    },
    exterior_walls: {
      label: 'الجدران الخارجية',
    },
    foundation: {
      label: 'مؤسسة',
    },
    roof_inspection_and_repair: {
      label: 'عمليات تفتيش السقف والإصلاح',
    },
    floor: {
      label: 'أرضية',
    },
    walls_and_ceilings: {
      label: 'الجدران والسقوف',
    },
    windows_and_doors: {
      label: 'النوافذ والأبواب',
    },
    electrical_system: {
      label: 'النظام الكهربائي',
    },
    fire_safety: {
      label: 'السلامة من الحرائق',
    },
    lighting: {
      label: 'إضاءة',
    },
    finishes_and_decorations: {
      label: 'التشطيبات والزخارف',
    },
    temperature: {
      label: 'درجة الحرارة',
    },
    architectural: {
      label: 'المعماري',
    },
    operational: {
      label: 'التشغيلية',
    },
    humidity: {
      label: 'الرطوبة',
    },
    special_water_conditions: {
      label: 'دورات المياه',
    },
    factor_type: {
      label: 'عامل',
    },
    images: {
      label: 'الصور',
    },
    expected_cost: {
      label: 'ما هي التكلفة المتوقعة؟',
      placeholder: 'على سبيل المثال 100',
      min_1: 'يجب أن يكون أكبر من 1',
      required: 'التكلفة المتوقعة مطلوبة',
    },
    name: {
      label: 'الاسم الكامل',
      required: 'الاسم الكامل ضروري',
    },
    phone_number: {
      label: 'رقم الهاتف',
      required: 'رقم الهاتف مطلوب',
      length: 'رقم الهاتف يجب أن يكون 9 أرقام',
    },
    relation_for_mosque: {
      label: 'علاقتك بالمسجد',
      required: 'العلاقة بالمسجد مطلوبة',
      options: {
        certified_resident: 'مقيم معتمد',
        imam: 'إمام',
        muezzin: 'مؤذن',
        observer: 'مراقب',
        prayer: 'مصلي',
      },
    },
    mosque_type: {
      label: 'نوع المسجد',
      required: 'نوع المسجد مطلوب',
    },
    created_date: {
      label: 'تاريخ تم إنشاؤه',
    },
    mosque_code: {
      label: 'رمز المسجد',
      required: 'رمز المسجد مطلوب',
      placeholder: 'على سبيل المثال: RY_0001',
    },
    city_village: {
      label: 'المدينة',
      required: 'المدينة مطلوبة',
    },
    district: {
      label: 'المنطقة',
      required: 'المنطقة مطلوبة',
    },
    street_name: {
      label: 'اسم الشارع',
      required: ' اسم الشارع مطلوب',
    },
    mosque_id: {
      label: 'معرف المسجد',
      required: 'مطلوب معرف المسجد',
    },
    mosque_national_code: {
      label: 'مدونة المسجد الوطنية',
      required: 'مطلوب رمز المسجد الوطني',
      invalid: 'يجب أن تكون قيمة التنسيق على سبيل المثال: 01-00221',
      placeholder: 'على سبيل المثال: 01-00221',
    },
    iso_code: {
      label: 'رمز ISO',
      placeholder: '01',
      required: 'رمز ISO مطلوب',
      invalid: 'يجب أن يكون رمز ISO على سبيل المثال: 01',
    },
    status: {
      label: 'الحالة',
    },
    from: 'من',
    to: 'حتى',
    search: 'بحث',
  },
  button: {
    approve_request: 'الموافقة على الطلب',
    reject_request: 'رفض الطلب',
    apply_for_evaluation: 'التقدم بطلب للتقييم',
    archive_evaluation: 'أرشفة هذا التقييم',
    add_photos: 'أضف صور',
    accept_request: 'قبول الطلب',
    approve_evaluation: 'الموافقة على التقييم',
    reject_evaluation: 'رفض التقييم',
    save_changes: 'حفظ التغييرات',
    filter: 'تصفية',
  },
};
