import { useParams } from 'react-router';
import { Helmet } from 'react-helmet-async';

import { DashboardPaths } from 'src/routes/paths';

import { useTranslate } from 'src/locales';
import { useGetIdeaById } from 'src/api/idea';

import IdeaEditView from 'src/sections/idea/view/idea-edit-view';

// ----------------------------------------------------------------------

type Props<T extends DashboardPaths> = {
  paths: T;
  title: string;
  activeRole: string;
};

export default function EditIdeaPage({ title, activeRole, paths }: Props<DashboardPaths>) {
  const { t } = useTranslate();
  const { id } = useParams();

  const { data: idea } = useGetIdeaById({
    idea_id: id || '',
  });

  return (
    <>
      <Helmet>
        <title>
          {t(title)} - {t(activeRole)}
        </title>
      </Helmet>

      <IdeaEditView paths={paths} currentIdea={idea?.data} />
    </>
  );
}
