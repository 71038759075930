import { Box, Stack, Skeleton } from '@mui/material';

import { TSocketMessageEntity } from 'src/socket-ticket/type';

import Scrollbar from 'src/components/scrollbar';
// import Lightbox, { useLightBox } from 'src/components/lightbox';

import useMessagesScroll from '../ticket/hooks/use-message-scroll';
import MajorDonorChatMessageItem from './major-donor-chat-message-item';

// ----------------------------------------------------------------------

type Props = {
  loading: boolean;
  messages: TSocketMessageEntity[];
};

export default function MajorDonorChatMessageList({ loading, messages = [] }: Props) {
  const { messagesEndRef } = useMessagesScroll(messages);

  return (
    <>
      <Scrollbar ref={messagesEndRef} sx={{ pt: 2.5, height: 1 }}>
        <Box>
          {loading
            ? Array.from({ length: 5 }).map((_, i) => (
                <Stack
                  key={i}
                  component="div"
                  direction={i % 2 === 0 ? 'row' : 'row-reverse'}
                  spacing={1}
                  sx={{
                    py: 1.25,
                  }}
                >
                  <Skeleton sx={{ width: 40, height: 40, borderRadius: '100%' }} />
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 0.75,
                      direction: i % 2 === 0 ? 'ltr' : 'rtl',
                    }}
                  >
                    <Skeleton sx={{ width: 75, height: 15 }} />
                    <Skeleton sx={{ width: 150, height: 15 }} />
                    <Skeleton sx={{ width: 125, height: 15 }} />
                  </Box>
                </Stack>
              ))
            : messages.length && !loading
            ? messages.map((message, i) => (
                <MajorDonorChatMessageItem
                  key={i}
                  index={i}
                  message={message}
                  messagesLength={messages.length}
                />
              ))
            : null}
        </Box>
      </Scrollbar>

      {/* <Lightbox
        index={lightbox.selected}
        slides={slides}
        open={lightbox.open}
        close={lightbox.onClose}
      /> */}
    </>
  );
}
