export const en = {
  title: {
    idea: 'Idea',
    all_ideas: 'All ideas',
    new_idea: 'New idea',
    create: 'Create new idea',
    edit: 'Edit idea',
    something_awesome: 'Write something awesome',
    drop_files: 'Drop files here or click to upload',
  },
  headers: {
    title: 'Title',
    description: 'Description',
    created_at: 'Created at',
    action: 'Action',
  },
  button: {
    view: 'View',
    edit_idea: 'Edit idea',
    delete_idea: 'Delete idea',
    add_idea: 'Add idea',
  },
  form: {
    title: {
      label: `Idea's Title`,
      required: `Idea's Title is required`,
    },
    description: {
      label: 'Description',
      required: 'Description is required',
    },
    image: {
      label: 'Cover image',
      required: 'Cover image url is required',
    },
    files: {
      label: 'Additional Files',
    },
  },
};
