import { Helmet } from 'react-helmet-async';

import { DashboardPaths } from 'src/routes/paths';

import { useTranslate } from 'src/locales';

import IdeaCreateView from 'src/sections/idea/view/idea-create-view';

// ----------------------------------------------------------------------

type Props<T extends DashboardPaths> = {
  paths: T;
  title: string;
  activeRole: string;
};

export default function AddIdeaPage({ title, activeRole, paths }: Props<DashboardPaths>) {
  const { t } = useTranslate();

  return (
    <>
      <Helmet>
        <title>
          {t(title)} - {t(activeRole)}
        </title>
      </Helmet>

      <IdeaCreateView paths={paths} title={title} />
    </>
  );
}
