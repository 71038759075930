import { isAxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { MessagesApi, TicketingApi, TicketingLogsApi } from '@otarid/ommar-sdk';

import { workerApisConfiguration } from 'src/utils/worker-configuration';

import { useAuthContext } from 'src/auth/hooks';

import { IQueryProps } from './campaign';

// ----------------------------------------------------------------------

export function useGetTickets(query: IQueryProps) {
  const { user } = useAuthContext();
  const ticketApi = new TicketingApi(workerApisConfiguration);
  const token = sessionStorage.getItem('accessToken') ?? '';

  const result = useQuery({
    queryKey: ['getTickets', { query }],
    queryFn: async () => {
      const response = await ticketApi.findManyTickets(query, {
        headers: {
          Authorization: `Bearer ${token}`,
          'x-chosen-role': user?.activeRole,
        },
      });

      return response.data;
    },
  });

  return result;
}

export function useGetTicket(id: string) {
  const { user } = useAuthContext();
  const ticketApi = new TicketingApi(workerApisConfiguration);
  const token = sessionStorage.getItem('accessToken') ?? '';

  const result = useQuery({
    queryKey: ['getDetaiTicket', { id }],
    queryFn: async () => {
      const response = await ticketApi
        .findById(
          {
            ticket_id: id,
            // include_relations: 'images,near_by',
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'x-chosen-role': user?.activeRole,
            },
          }
        )
        .catch((error) => {
          if (isAxiosError(error)) {
            throw new Error(error?.response?.data.message || error.message);
          }

          throw new Error(error.message);
        });

      return response.data.data;
    },
    enabled: Boolean(id || id !== ''),
  });

  return result;
}

export function useGetTicketMessages(room_id: string) {
  const { user } = useAuthContext();
  const messagesApi = new MessagesApi(workerApisConfiguration);
  const token = sessionStorage.getItem('accessToken') ?? '';

  const result = useQuery({
    queryKey: ['getTicketMessages', { room_id }],
    queryFn: async () => {
      const response = await messagesApi
        .findAll(
          {
            room_id,
            sort_by: 'updated_at',
            sort_direction: 'asc',
            include_relations: 'SENDER',
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'x-chosen-role': user?.activeRole,
            },
          }
        )
        .catch((error) => {
          if (isAxiosError(error)) {
            throw new Error(error?.response?.data.message || error.message);
          }

          throw new Error(error.message);
        });

      return response.data.data;
    },
    enabled: Boolean(room_id || room_id !== ''),
  });

  return result;
}

export function useGetTicketLogs(ticket_id: string) {
  const { user } = useAuthContext();
  const ticketLogsApi = new TicketingLogsApi(workerApisConfiguration);
  const token = sessionStorage.getItem('accessToken') ?? '';

  const result = useQuery({
    queryKey: ['getTicketMessages', { ticket_id }],
    queryFn: async () => {
      const response = await ticketLogsApi
        .findById(
          {
            ticket_id,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'x-chosen-role': user?.activeRole,
            },
          }
        )
        .catch((error) => {
          if (isAxiosError(error)) {
            throw new Error(error?.response?.data.message || error.message);
          }

          throw new Error(error.message);
        });

      return response.data.data;
    },
    enabled: Boolean(ticket_id || ticket_id !== ''),
  });

  return result;
}

export function useGetTicketsStatistics() {
  const { user } = useAuthContext();
  const token = sessionStorage.getItem('accessToken') ?? '';
  const ticketApi = new TicketingApi(workerApisConfiguration);

  const result = useQuery({
    queryKey: ['getTicketsStatistics', { user }],
    queryFn: async () => {
      const response = await ticketApi
        .getTicketsStatistic({
          headers: {
            Authorization: `Bearer ${token}`,
            'x-chosen-role': user?.activeRole,
          },
        })
        .catch((error) => {
          if (isAxiosError(error)) {
            throw new Error(error?.response?.data.message || error.message);
          }

          throw new Error(error.message);
        });

      return response.data.data;
    },
  });

  return result;
}
