import { isAxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { RoomChatModuleApi } from '@otarid/ommar-sdk';

import { workerApisConfiguration } from 'src/utils/worker-configuration';

import { useAuthContext } from 'src/auth/hooks';

export function useGetRoomParticipants(id: string) {
  const { user } = useAuthContext();
  const token = sessionStorage.getItem('accessToken') ?? '';
  const roomChatModule = new RoomChatModuleApi(workerApisConfiguration);

  const result = useQuery({
    queryKey: ['getParticipants', { id }],
    queryFn: async () =>
      roomChatModule
        .getParticipants(
          { id },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'x-chosen-role': user?.activeRole,
            },
          }
        )
        .catch((error) => {
          if (isAxiosError(error)) {
            throw new Error(error?.response?.data.message || error.message);
          }

          throw new Error(error.message);
        }),
    enabled: Boolean(id || id !== ''),
  });

  return result.data?.data.data;
}
