import { Controller, useFormContext } from 'react-hook-form';

import TextField, { TextFieldProps } from '@mui/material/TextField';

// ----------------------------------------------------------------------

type Props = TextFieldProps & {
  name: string;
};

export default function RHFTextField({ name, helperText, type, ...other }: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          fullWidth
          type={type}
          value={
            typeof field.value === 'number' && field.value === 0 ? '' : field.value
            // ? ['latitude', 'longitude'].includes(name)
            //   ? 0
            //   : ''
            // : field.value
          }
          onChange={(event) => {
            const inputValue = event.target.value;

            if (type === 'number') {
              field.onChange(Number(inputValue));
            } else if (['bank_account_number'].includes(name)) {
              let cleanedValue = inputValue.replace(/\s+/g, '').replace(/\D/g, '');
              cleanedValue = cleanedValue.slice(0, 22);
              cleanedValue = cleanedValue.replace(/(.{4})/g, '$1 ');

              field.onChange(cleanedValue);
            } else if (name === 'mosque_national_code') {
              let mnc = inputValue.replace(/\D/g, '');
              if (mnc.length > 2) {
                mnc = `${mnc.slice(0, 2)}-${mnc.slice(2)}`;
              }

              field.onChange(mnc);
            } else {
              field.onChange(inputValue);
            }
          }}
          error={!!error}
          helperText={error ? error?.message : helperText}
          {...other}
        />
      )}
    />
  );
}
